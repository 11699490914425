import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        usersRoles: [],
        usersUnits: [],
    },
    getters: {
        usersRoles: (state) => state.usersRoles,
        usersUnits: (state) => state.usersUnits,
        userUnits: (state) => (userId) => (
            state.usersUnits.filter((uu) => uu.user_id === userId)
        ),
        engineersOfUnit: (state, getters) => (unitId) => (
            state.usersUnits
                .filter((uu) => uu.unit_id === unitId)
                .filter((uu) => getters.users.map((e) => e.id).includes(uu.user_id))
                .filter((uu) => {
                    const roles = state.usersRoles
                        .filter((ur) => ur.user_id === uu.user_id)
                        .map((ur) => ur.role_id);

                    // TODO: make sign for engineers
                    return roles.includes(2);
                })
        ),
    },
    actions: {
        getUsersRolesFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/user_role`)
                    .then((res) => {
                        commit("setUsersRoles", res.data.usersRoles);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUsersUnitsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/user_unit`)
                    .then((res) => {
                        commit("setUsersUnits", res.data.usersUnits);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addUserUnit({ commit, state }, { userId, unitId }) {
            return new Promise((resolve) => {
                if (!state.usersUnits.find((uu) => uu.user_id === userId && uu.unit_id === unitId)) {
                    commit("addUserUnit", { userId, unitId });
                }
                resolve();
            });
        },
        removeUserUnit({ commit }, payload) {
            commit("removeUserUnit", payload);
        },
        removeUserUnits({ commit }, userId) {
            return new Promise((resolve) => {
                commit("removeUserUnits", userId);
                resolve();
            });
        },
    },
    mutations: {
        setUsersRoles(state, usersRoles) {
            state.usersRoles = usersRoles;
        },
        setUsersUnits(state, usersUnits) {
            state.usersUnits = usersUnits;
        },
        addUserUnit(state, { userId, unitId }) {
            state.usersUnits.push({
                id: Date.now(),
                user_id: userId,
                unit_id: unitId,
            });
        },
        removeUserUnit(state, { userId, unitId }) {
            const index = state.usersUnits.findIndex((ur) => ur.user_id === userId && ur.unit_id === unitId);
            if (index >= 0) {
                state.usersUnits.splice(index, 1);
            }
        },
        removeUserUnits(state, userId) {
            state.usersUnits = state.usersUnits.filter((uu) => uu.user_id !== userId);
        },
    },
};
