<template>
    <q-select
        v-model="programId"
        outlined
        dense
        :options="LEARNING_TYPES"
        mapOptions
        emitValue
        label="Вид обучения"
        class="cell-span-col-6"
        hideBottomSpace
        errorMessage="Укажите вид обучения"
        :error="requestValidations.validations.program_id && !programId"
        :readonly="!canBeEdited"
    />
    <Moving_2_Result :canBeEdited="canBeEdited" />
    <div class="cell-span-col-6 learning-students">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            ФИО
        </div>
        <div class="cell cell-header cell-center">
            Должность
        </div>
        <div class="cell cell-header cell-center">
            <q-btn
                round
                size="sm"
                :icon="matAdd"
                color="white"
                textColor="black"
                @click="addStudent"
            />
        </div>
        <template
            v-for="(student, index) in students"
            :key="index"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell">
                <q-input
                    v-model="student.name"
                    borderless
                    dense
                    style="width: 100%;"
                    @update:modelValue="saveStudents"
                />
            </div>
            <div class="cell">
                <q-input
                    v-model="student.post"
                    borderless
                    dense
                    style="width: 100%;"
                    @update:modelValue="saveStudents"
                />
            </div>
            <div class="cell cell-center">
                <q-btn
                    round
                    size="sm"
                    :icon="matDelete"
                    color="white"
                    textColor="grey"
                    @click="delStudent(index)"
                />
            </div>
        </template>
    </div>
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="Сформировать Акт обучения"
        :disable="denyAct || !canBeEdited"
        @click="createActLearning"
    />
    <div class="cell-span-col-4" />
</template>

<script>
import { mapGetters } from "vuex";
import { createDoc } from "@/helpers/createDoc";
import { tc } from "@/helpers/tableCell";
import { date } from "quasar";
import {
    REQUEST_STATUS_VALUES,
    LEARNING_TYPES,
    DATE_LOCALE_2,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";
import Moving_2_Result from "./Moving_2_Result.vue";
import DialogSigners from "../DialogSigners/DialogSigners.vue";

export default {
    name: "DialogEditRequestMovingLearning",    // eslint-disable-line
    components: {
        Moving_2_Result,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            LEARNING_TYPES,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            requestValidations: "requestValidations",
            requestDocs: "requestDocs",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            learningTypeName: "learningTypeName",
            getUserFIOshort: "getUserFIOshort",
            getUserPostTitle: "getUserPostTitle",
            getUserDueOrder: "getUserDueOrder",
            unitAddress: "unitAddress",
        }),
        studentsList: generateRequestProperty("students_list"),
        students() {
            let ss;
            try {
                ss = JSON.parse(this.studentsList) || [];
            }
            catch {
                ss = [];
            }
            return ss;
        },
        denyAct() {
            return this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                !this.request.result ||
                !this.request.note ||
                this.request.note.length < 4;
        },
        programId: {
            get() {
                return this.request.program_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "program_id",
                        value,
                    },
                );
            },
        },
    },
    methods: {
        //--------------------------------------------------------------------------
        getTableStudentsList() {
            const tableWidth = 9700;
            const header = `
                <w:tblPr>
                <w:jc w:val="center"/>
                <w:tblW w:type="dxa" w:w="${tableWidth}"/>
                <w:tblBorders>
                    <w:top w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                    <w:start w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                    <w:bottom w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                    <w:end w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                    <w:insideH w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                    <w:insideV w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                </w:tblBorders>
                <w:tblCellMar>
                    <w:top w:w="100" w:type="dxa"/>
                    <w:start w:w="100" w:type="dxa"/>
                    <w:bottom w:w="100" w:type="dxa"/>
                    <w:end w:w="100" w:type="dxa"/>
                </w:tblCellMar>
                </w:tblPr>
                <w:tblGrid>
                    <w:gridCol w:w="${Math.round(tableWidth / 3)}" />
                    <w:gridCol w:w="${Math.round(tableWidth / 3)}" />
                    <w:gridCol w:w="${Math.round(tableWidth / 3)}" />
                </w:tblGrid>
                <w:tr>
                ${tc(null, null, "center", "ФИО")}
                ${tc(null, null, "center", "Должность")}
                ${tc(null, null, "center", "Подпись")}
                </w:tr>
                `;

            let data = "";
            for (let index = 0; index < this.students.length; index++) {
                const row = [
                    tc(null, null, null, this.students[index].name),
                    tc(null, null, null, this.students[index].post),
                    tc(null, null, null, ""),
                ]
                    .join("");

                data = `${data}<w:tr>${row}</w:tr>`;
            }

            return `<w:tbl>${header}${data}</w:tbl>`;
        },

        createActLearning() {
            if (this.requestDocs
                .map((rd) => rd.file_name)
                .findIndex((rd) => (
                    rd.startsWith("act_learning_") && rd.endsWith(`_${this.request.number}.docx`)
                )) >= 0) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                    html: true,
                });
            }
            else {
                this.$q.dialog({
                    component: DialogSigners,
                })
                    .onOk((
                        signers,
                        signType,
                    ) => {
                        const dateDoc = date.formatDate(
                            Date.now(),
                            "YYYY_MM_DD",
                        );
                        const dateDocLong = date.formatDate(
                            Date.now(),
                            "«DD» MMMM YYYY г.",
                            DATE_LOCALE_2,
                        );
                        createDoc(
                            "act-learning.docx",
                            `act_learning_${dateDoc}_${this.request.number}.docx`,
                            {
                                act_date: dateDocLong,
                                act_po_title: this.learningTypeName(this.request.program_id),
                                act_org_title: this.getOrgProperty(
                                    this.getOrgIdOfUnit(this.request.unit_id),
                                    "name",
                                ),
                                act_org_address: this.unitAddress(this.request.unit_id),
                                act_org_vncode: this.getOrgProperty(
                                    this.getOrgIdOfUnit(this.request.unit_id),
                                    "vncode",
                                ),
                                signer_unit_post: this.getUserPostTitle(signers[1].id),
                                signer_unit_fio_short: this.getUserFIOshort(signers[1].id),
                                signer_unit_dueorder: this.getUserDueOrder(signers[1].id) ||
                                    signers[1].dueOrder,
                                signer_iac_post: this.getUserPostTitle(signers[0].id),
                                signer_iac_fio_short: this.getUserFIOshort(signers[0].id),
                                studentsList: this.getTableStudentsList(),
                            },
                        )
                            .then((fileDoc) => {
                                this.$store.dispatch(
                                    "addRequestDoc",
                                    {
                                        file_name: fileDoc.name,
                                        mimetype: fileDoc.type,
                                        section: "requestDocs",
                                        blob: fileDoc,
                                        signers_count: signers[2].id ? 3 : 2,
                                        signer_iac_id: signers[0].id,
                                        signer_unit_id: signers[1].id,
                                        signer_final_id: signers[2].id,
                                        sign_type: signType,
                                    },
                                );
                            });
                    });
            }
        },
        addStudent() {
            this.studentsList = JSON.stringify([
                ...this.students,
                {
                    name: "",
                    post: "",
                },
            ]);
        },
        delStudent(index) {
            this.studentsList = JSON.stringify([
                ...this.students.filter((s, i) => i !== index),
            ]);
        },
        saveStudents() {
            this.studentsList = JSON.stringify(this.students);
        },
    },
};
</script>
