<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Создание журнала архива заявок
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    class="q-mb-sm"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    REPAIRS_RESULTS,
    REPAIRS_TYPES,
    OPTIONS_VEIW_BEFORE,
    PERFORM_RESULTS_PO_SPECIAL,
} from "@/store/constants";
import { getRequestsForArchive } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateRequestsArchive",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const { requests } = await getRequestsForArchive({
                region_id: this.regionId,
                date_beg: this.dateStart,
                date_end: this.dateFinsh,
            });

            const requestItems = await this.getRequestsItems(requests);
            const regionName = this.getRegionName(this.regionId);
            const outFileName = `${regionName}. Журнал учета заявок в архив за период с ${this.dateStart} по ${this.dateFinsh}.docx`;

            const replacement = {
                enc_date_start: this.dateStart,
                enc_date_finsh: this.dateFinsh,
                items: requestItems,
            };

            createDoc(
                "requests-archive.docx",
                outFileName,
                replacement,
            )
                .then((requestsarchive) => {
                    saveDoc(requestsarchive);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRequestsItems(requests) {
            const items = [];

            for (let i = 0; i < requests.length; i++) {
                this.valueProgress = Math.round((i / requests.length) * 100);

                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.MISTAKE ||
                    requests[i].request_kind_id === REQUEST_KINDS_VALUES.REVOKE
                ) {
                    continue;
                }

                const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                const orgName = this.getOrgProperty(orgId, "name");
                const emplFio = this.getUserFIO(requests[i].contact_id);
                const emplPost = this.getUserPostTitle(requests[i].contact_id);

                let result5 = "";

                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        const repairRes = REPAIRS_RESULTS
                            .find((rr) => rr.value === requests[i].repair_result_id);
                        const repairType = REPAIRS_TYPES
                            .find((rt) => rt.value === requests[i].repair_type_id);

                        result5 = [
                            repairRes ? repairRes.label : "",
                            repairType ? `, ${repairType.label}` : "",
                        ].join("");
                    }
                    else {
                        const resBefore = OPTIONS_VEIW_BEFORE
                            .find((o) => o.value === requests[i].view_res_before);

                        result5 = [
                            resBefore ? resBefore.label : "",
                            requests[i].comment_before ? `, ${requests[i].comment_before}` : "",
                        ].join("");
                    }
                }
                else {
                    const performRes = PERFORM_RESULTS_PO_SPECIAL
                        .find((r) => r.value === requests[i].result);
                    result5 = [
                        performRes ? performRes.label : "",
                        requests[i].note ? `, ${requests[i].note}` : "",
                    ].join("");
                }

                const requestDateTime = date.formatDate(
                    requests[i].request_date_add,
                    "DD.MM.YYYY HH:mm:ss",
                );

                let result6 = "";
                if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    result6 = requests[i].result_d
                        ? date.formatDate(requests[i].result_d, "DD.MM.YYYY")
                        : "";
                }

                let result8 = "";
                if (requests[i].hotlinegas_number != null) {
                    result8 += "Номер на ГЛ: ";
                    result8 += requests[i].hotlinegas_number;
                }

                const item = {
                    num: requests[i].number,
                    request_date: requestDateTime,
                    request_object: `${orgName}, ${emplFio}, ${emplPost}`,
                    request_text: requests[i].request_text || "",
                    request_result: result5,
                    result_date: result6,
                    answer_address: `${orgName}`,
                    comment: result8,
                };
                items.push(item);
            }
            return items;
        },
    },
};
</script>
