import axios from "axios";
import {
    siteHost,
} from "@/store/constants";

export default {
    state: {
        requestDocs: [],
    },
    getters: {
        requestDocs: (state) => state.requestDocs,
    },
    actions: {
        getRequestDocs({ commit }, requestId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_docs/${requestId}`)
                    .then((res) => {
                        commit("setRequestDocs", res.data.files);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRequestDocs({ commit }, docs) {
            return new Promise((resolve) => {
                commit("setRequestDocs", docs);
                resolve();
            });
        },
        addRequestDoc({ commit }, doc) {
            commit("addRequestDoc", doc);
        },
        delRequestDoc({ commit }, index) {
            commit("delRequestDoc", index);
        },
    },
    mutations: {
        setRequestDocs(state, docs) {
            state.requestDocs = docs;
        },
        addRequestDoc(state, doc) {
            state.requestDocs.push(doc);
        },
        delRequestDoc(state, index) {
            state.requestDocs.splice(index, 1);
        },
    },
};
