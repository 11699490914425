<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Программное обеспечение
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-select
                    v-if="item.type == 2"
                    v-model="item.gas_sub_system_id"
                    outlined
                    dense
                    :options="programsSubsystems"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Подсистема ГАС &quot;Правосудие&quot;"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />
                <q-input
                    ref="programName"
                    v-model="item.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                    :rules="[val => val && val.length > 0 || 'Укажите наименование']"
                    hideBottomSpace
                />
                <q-input
                    ref="programVersion"
                    v-model="item.version"
                    outlined
                    dense
                    label="Версия"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                    :rules="[val => val && val.length > 0 || 'Укажите версию']"
                    hideBottomSpace
                />

                <q-input
                    v-model="item.designation"
                    outlined
                    dense
                    label="Обозначение"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />
                <q-input
                    v-model="item.factory_number"
                    outlined
                    dense
                    label="Заводской номер"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />
                <q-input
                    v-model="item.guaranty_dt"
                    outlined
                    dense
                    label="Срок гарантии"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />

                <q-input
                    ref="programDescription"
                    v-model="item.description"
                    outlined
                    dense
                    label="Описание ПО"
                    class="q-mb-sm"
                    autogrow
                    :readonly="!canBeEdited"
                    :rules="[(val) => val.length < 2000 || 'Не более 2000 символов']"
                    hideBottomSpace
                />
                <q-field
                    ref="programType"
                    v-model="item.type"
                    outlined
                    label="Тип программного обеспечения"
                    stackLabel
                    :rules="[val => !!val || 'Укажите тип программного обеспечения']"
                    hideBottomSpace
                    class="q-pb-sm"
                >
                    <q-option-group
                        v-model="item.type"
                        :options="PROGRAM_TYPES"
                        color="primary"
                        :disable="!canBeEdited"
                    />
                </q-field>
                <q-input
                    v-if="item.type == 2"
                    v-model="item.distribution_number"
                    outlined
                    dense
                    label="Номер дистрибутивного комплекта"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />
                <q-select
                    v-model="item.programs_org_types"
                    outlined
                    dense
                    multiple
                    :options="orgKinds"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Типы организаций"
                    class="q-mb-sm"
                    :readonly="!canBeEdited"
                />
                <q-btn
                    v-if="canBeEdited"
                    label="Выбрать все типы"
                    dense
                    flat
                    color="primary"
                    :disable="item.programs_org_types.length >= orgKinds.length"
                    @click="item.programs_org_types = orgKinds.map((k) => k.id)"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="canBeEdited"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    :label="canBeEdited ? 'Отменить' : 'Закрыть'"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS, PROGRAM_TYPES } from "@/store/constants";

export default {
    name: "DialogEditProgram",
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                id: null,
                gas_sub_system_id: null,
                title: "",
                type: 1,
                version: "",
                description: "",
                distribution_number: "",
                programs_org_types: [],
            }),
        },
    },
    emits: ["ok"],
    setup() {
        return {
            PROGRAM_TYPES,
        };
    },
    data() {
        return {
            item: {},
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            orgKinds: "orgKinds",
            programsSubsystems: "programsSubsystems",
        }),
        canBeEdited() {
            return !!(this.currentUser.rightsObject.RIGHT_PROGRAMS & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        show() {
            this.item = {
                ...this.itemForEdit,
                programs_org_types: this.itemForEdit.programs_org_types
                    ? this.itemForEdit.programs_org_types
                        .map((e) => e.org_type_id)
                        .filter((t) => this.orgKinds.map((k) => k.id).includes(t))
                    : [],
            };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.programName.validate();
            this.$refs.programDescription.validate();
            this.$refs.programVersion.validate();
            this.$refs.programType.validate();

            if (!this.$refs.programName.hasError &&
                !this.$refs.programDescription.hasError &&
                !this.$refs.programVersion.hasError &&
                !this.$refs.programType.hasError) {
                this.$emit("ok", this.item);

                this.hide();
            }
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
