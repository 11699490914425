<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Модуль формирования табличной части формуляра
                </div>
            </q-card-section>

            <q-separator />

            <div class="formular-grid">
                <SelectFilter
                    ref="refUnit"
                    v-model="unitId"
                    :options="unitsFiltered"
                    label="Объект"
                    clearable
                    class="cell-span-col-2"
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    extendedScope
                    hideBottomSpace
                    :rules="[val => val || 'Нужно выбрать объект']"
                />
                <PoeDatePicker
                    v-model="date_beg"
                    labelTitle="Дата начала отчета"
                    :doValidate="false"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    v-model="date_end"
                    labelTitle="Дата окончания отчета"
                    :doValidate="false"
                />
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import {
    RIGHTS_CONSTANTS,
    REQUEST_KINDS_VALUES,
    DATE_LOCALE_2,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import { getRequestsFormular } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateFormular",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    data() {
        return {
            unitId: null,
            date_beg: date.formatDate(date.startOfDate(new Date(), "year"), "DD.MM.YYYY"),
            date_end: date.formatDate(date.endOfDate(new Date(), "year"), "DD.MM.YYYY"),
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
            currentUser: "currentUser",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
        }),
        unitsFiltered() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => this.currentUser.units.includes(u.id));
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            if (!this.unitId) {
                return;
            }

            getRequestsFormular({
                unitId: this.unitId,
                date_beg: this.date_beg,
                date_end: this.date_end,
            })
                .then(async (res) => {
                    const replacement = {
                        items: await this.getRequestsItems(res.requests),
                    };

                    const vncode = this.getOrgProperty(
                        this.getOrgIdOfUnit(this.unitId),
                        "vncode",
                    );
                    const year = new Date(this.date_beg).getFullYear();

                    createDoc(
                        "formular.docx",
                        `${vncode} Формуляр (${year}).docx`,
                        replacement,
                    )
                        .then((formular) => {
                            saveDoc(formular);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRequestsItems(requests) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                const tool = await this.$store.dispatch(
                    "getSingleToolFromDB",
                    requests[i].tool_id,
                );

                const notes = [
                    `Заявка № ${requests[i].number}`,
                    tool ? this.getToolKindName(tool.tool_kind_id) : "",
                    tool ? this.getToolModelName(tool.model_id) : "",
                    tool ? tool.inventar_number : "",
                    tool ? tool.serial_number : "",
                    requests[i].request_text,
                ].join(" ");

                let deals = "";
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    deals = [
                        requests[i].comment_before,
                        requests[i].co_7_number,
                        requests[i].co_7_d
                            ? date.formatDate(
                                requests[i].co_7_d,
                                "YYYY-MM-DD",
                            )
                            : "",
                        requests[i].comment_after,
                        requests[i].co_8_number,
                        requests[i].co_8_d
                            ? date.formatDate(
                                requests[i].co_8_d,
                                "YYYY-MM-DD",
                            )
                            : "",
                    ].join(" ");
                }
                else {
                    deals = [
                        requests[i].note,
                        requests[i].co_3_number,
                        requests[i].co_3_d
                            ? date.formatDate(
                                requests[i].co_3_d,
                                "«DD» MMMM YYYY г.",
                                DATE_LOCALE_2,
                            )
                            : "",
                    ].join(" ");
                }

                const engineers = await this.$store.dispatch(
                    "getRequestEngineers",
                    requests[i].id,
                );
                const engineer0 = engineers.length > 0
                    ? [
                        this.getUserPostTitle(engineers[0]),
                        this.getUserFIO(engineers[0]),
                    ].join(" ")
                    : "";

                const item = {
                    date: this.$dayjs(requests[i].request_date_add).format("DD.MM.YYYY"),
                    note: notes,
                    deal: deals,
                    engineer: engineer0,
                };
                items.push(item);
            }
            return items;
        },
    },
};
</script>
