import axios from "axios";
import { siteHost } from "./constants";

const toolsSetsFilterEmpty = {
    unit_id: null,
    inventar_exact: false,
    tool_work_in_dt_from: null,
    tool_work_in_dt_till: null,
    tool_set_name_id: null,
};

export default {
    state: {
        toolsSetsNames: [],

        toolsSets: [],
        toolsSetsCount: 0,
        toolsSetsFilter: { ...toolsSetsFilterEmpty },
    },
    getters: {
        toolsSetsNames: (state) => state.toolsSetsNames,
        toolsSetsNamesCodes: (state) => state.toolsSetsNames.map((t) => ({
            ...t,
            name: `${t.code} ${t.name}`,
        })),
        toolsSetName: (state) => (toolsSetTypeId) => {
            const type = state.toolsSetsNames.find((t) => t.id === toolsSetTypeId);
            return type
                ? type.name
                : "";
        },
        toolsSets: (state) => state.toolsSets,
        toolsSetsCount: (state) => state.toolsSetsCount,
        toolsSetsFilter: (state) => state.toolsSetsFilter,
    },
    actions: {
        //----------------------------------------------------------------------
        getToolsSetsNamesFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tools_sets_names`)
                    .then((res) => {
                        commit("setToolsSetsNames", res.data.tools_sets_names);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolsSetName({ dispatch }, toolsSetName) {
            const method = toolsSetName.id
                ? "put"
                : "post";

            const url = toolsSetName.id
                ? `${siteHost}/api/tools_sets_names/${toolsSetName.id}`
                : `${siteHost}/api/tools_sets_names`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    toolsSetName,
                )
                    .then(() => {
                        dispatch("getToolsSetsNamesFromDB");
                        resolve("Вид комплекта оборудования сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteToolsSetName({ dispatch }, toolsSetNameId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/tools_sets_names/${toolsSetNameId}`)
                    .then(() => {
                        dispatch("getToolsSetsNamesFromDB");
                        resolve("Вид комплекта оборудования удален");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        //----------------------------------------------------------------------
        getToolsSetsFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get(
                    `${siteHost}/api/tools_sets`,
                    {
                        params: {
                            filter: getters.toolsSetsFilter,
                            pageNumber: getters.toolsSetsPageNumber,
                        },
                    },
                )
                    .then((res) => {
                        commit("setToolsSets", res.data.tools_sets);
                        commit("setToolsSetsCount", res.data.itemsCount);
                        commit("setToolsSetsPagesCount", res.data.pagesCount);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolsSet({ dispatch }, { toolsSet, toolsSetTools, oldToolsIds }) {
            const method = toolsSet.id
                ? "put"
                : "post";

            const url = toolsSet.id
                ? `${siteHost}/api/tools_sets/${toolsSet.id}`
                : `${siteHost}/api/tools_sets`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    toolsSet,
                )
                    .then(async (res) => {
                        for (let i = 0; i < oldToolsIds.length; i++) {
                            if (!toolsSetTools.map((t) => t.id).includes(oldToolsIds[i])) {
                                await dispatch(
                                    "updateSingleToolInDB",
                                    {
                                        id: oldToolsIds[i],
                                        tools_set_id: null,
                                    },
                                );
                            }
                        }

                        for (let i = 0; i < toolsSetTools.length; i++) {
                            await dispatch(
                                "saveTool",
                                {
                                    ...toolsSetTools[i],
                                    inventar_number: toolsSet.inventar_number,
                                    tools_set_id: res.data.newToolSetId,
                                },
                            );
                        }

                        dispatch("getToolsSetsFromDB");
                        resolve("Комплект оборудования сохранен");
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err.response.data);
                    });
            });
        },
        getToolsBySetId(context, toolsSetId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tools_by_set_id/${toolsSetId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setToolsSetsFilter({ commit, dispatch }, toolsSetsFilter) {
            commit("setToolsSetsFilter", toolsSetsFilter);
            commit("setToolsSetsPageNumber", 1);
            commit("setMainSpinnerState", true);
            dispatch("getToolsSetsFromDB")
                .then(() => {
                    commit("setMainSpinnerState", false);
                });
        },
        //----------------------------------------------------------------------
        toolsSetsSearch() {
            axios.get(`${siteHost}/api/tools_sets_search`)
                .then((res) => {
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mutations: {
        setToolsSetsNames(state, toolsSetsNames) {
            state.toolsSetsNames = toolsSetsNames;
        },
        setToolsSets(state, toolsSets) {
            state.toolsSets = toolsSets;
        },
        setToolsSetsCount(state, toolsSetsCount) {
            state.toolsSetsCount = toolsSetsCount;
        },
        setToolsSetsFilter(state, toolsSetsFilter) {
            state.toolsSetsFilter = toolsSetsFilter || { ...toolsSetsFilterEmpty };
        },
    },
};
