<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета по отказам ПТС за период
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    :futureDateAllowed="false"
                    labelTitle="Дата начала отчета"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    :icon="matSave"
                    color="primary"
                    flat
                    label="Сформировать"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    :icon="matCancel"
                    color="primary"
                    flat
                    label="Отменить"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
} from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import { getRequestsRepairReport } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateFailureReportForm",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            getToolKindName: "getToolKindName",
            postTitle: "postTitle",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    watch: {
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refRegionId.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refRegionId.hasError && !this.allRegions)) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            let requestItems = [];

            for (let i = 0; i < this.regions.length; i++) {
                if (this.allRegions) this.valueProgress = Math.round((i / this.regions.length) * 100);
                const currentRegionId = this.regions[i].id;
                if ((this.allRegions && currentRegionId < 90) || (!this.allRegions && currentRegionId === this.regionId)) {
                    const { requests } = await getRequestsRepairReport({
                        region_id: currentRegionId,
                        date_beg: this.dateStart,
                        date_end: this.dateFinsh,
                    });
                    const item = await this.getFailureReportFormItems(requests);
                    item.region_name = this.getRegionName(currentRegionId);
                    requestItems = requestItems.concat(item);
                }
            }

            const requestCounters = await this.getRequestCounters(requestItems);
            const outFileName = `Отчет по отказам ПТС за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                items: requestItems,
                arm_all: requestCounters.get("ARM"),
                server_all: requestCounters.get("SERVER"),
                vks_all: requestCounters.get("VKS"),
                femida_all: requestCounters.get("FEMIDA"),
                ibp_all: requestCounters.get("IBP"),
                kiosk_all: requestCounters.get("KIOSK"),
                printer_all: requestCounters.get("PRINTER"),
                net_all: requestCounters.get("NET"),
                other_all: requestCounters.get("OTHER"),
            };

            createXls(
                this.siteHost,
                "failure-report-form.xlsx",
                outFileName,
                replacement,
            )
                .then((failureReport) => {
                    saveXls(failureReport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getFailureReportFormItems(requests) {
            const item = {
                region_name: null,
                arm_region: 0,
                server_region: 0,
                vks_region: 0,
                femida_region: 0,
                ibp_region: 0,
                kiosk_region: 0,
                printer_region: 0,
                net_region: 0,
                other_region: 0,
            };
            for (let i = 0; i < requests.length; i++) {
                if (!this.allRegions) this.valueProgress = Math.round((i / requests.length) * 100);
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    const tool = await this.$store.dispatch(
                        "getSingleToolFromDB",
                        requests[i].tool_id,
                    );
                    const toolKindName = this.getToolKindName(tool.tool_kind_id);
                    if (toolKindName.includes("Системный блок компьютера")) {
                        item.arm_region += 1;
                    }
                    else if (toolKindName.includes("Сервер")) {
                        item.server_region += 1;
                    }
                    else if (toolKindName.includes("Видеокодек")) {
                        item.vks_region += 1;
                    }
                    else if (toolKindName.includes("Микшер")) {
                        item.femida_region += 1;
                    }
                    else if (toolKindName.includes("Источник бесперебойного питания")) {
                        item.ibp_region += 1;
                    }
                    else if (toolKindName.includes("Информационный киоск")) {
                        item.kiosk_region += 1;
                    }
                    else if (toolKindName.includes("Принтер") || toolKindName.includes("МФУ") ||
                        toolKindName.includes("Копировально-множительный аппарат") || toolKindName.includes("Сканер")) {
                        item.printer_region += 1;
                    }
                    else if (toolKindName.includes("Коммутатор сетевой") || toolKindName.includes("Маршрутизатор")) {
                        item.net_region += 1;
                    }
                    else {
                        item.other_region += 1;
                    }
                }
            }
            return item;
        },
        async getRequestCounters(requestItems) {
            let arm_count = 0;
            let server_count = 0;
            let vks_count = 0;
            let femida_count = 0;
            let ibp_count = 0;
            let kiosk_count = 0;
            let printer_count = 0;
            let net_count = 0;
            let other_count = 0;
            for (let i = 0; i < requestItems.length; i++) {
                arm_count += requestItems[i].arm_region;
                server_count += requestItems[i].server_region;
                vks_count += requestItems[i].vks_region;
                femida_count += requestItems[i].femida_region;
                ibp_count += requestItems[i].ibp_region;
                kiosk_count += requestItems[i].kiosk_region;
                printer_count += requestItems[i].printer_region;
                net_count += requestItems[i].net_region;
                other_count += requestItems[i].other_region;
            }
            const counters = new Map();
            counters.set("ARM", arm_count);
            counters.set("SERVER", server_count);
            counters.set("VKS", vks_count);
            counters.set("FEMIDA", femida_count);
            counters.set("IBP", ibp_count);
            counters.set("KIOSK", kiosk_count);
            counters.set("PRINTER", printer_count);
            counters.set("NET", net_count);
            counters.set("OTHER", other_count);
            return counters;
        },
    },
};
</script>
