import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        users: [],
        filter: {
            region_id: null,
            organization_id: null,
            post: null,
            surname: null,
            name: null,
            patronymic: null,
        },
        filterUsersHotline: {
            surname: null,
            name: null,
            patronymic: null,
            programs_ids: [],
        },
    },
    getters: {
        users: (state, getters) => state.users.map((u) => {
            const accessLevel = Math.max(
                0,
                ...getters.roles
                    .filter((r) => u.users_roles.map((ur) => ur.role_id).includes(r.id))
                    .map((r) => r.access_level),
            );

            return {
                ...u,
                accessLevel,
            };
        }),
        usersOfRegion: (state, getters) => {
            const orgsIds = getters.orgs
                .filter((o) => o.region_id === getters.currentUser.region_id)
                .map((o) => o.id);

            return state.users.filter((u) => orgsIds.includes(u.organization_id));
        },
        usersFiltered: (state, getters) => {
            let us = [...state.users].filter((u) => u.hotline_worker !== true);

            if (state.filter.region_id) {
                const orgsOfRegionIds = getters.orgs
                    .filter((o) => o.region_id === state.filter.region_id)
                    .map((o) => o.id);
                us = us.filter((u) => orgsOfRegionIds.includes(u.organization_id));
            }
            if (state.filter.organization_id) {
                us = us.filter((e) => e.organization_id === state.filter.organization_id);
            }

            if (state.filter.unit_id) {
                const usersOfUnitIds = getters.usersUnits
                    .filter((uu) => uu.unit_id === state.filter.unit_id)
                    .map((uu) => uu.user_id);

                us = us.filter((e) => usersOfUnitIds.includes(e.id));
            }

            if (state.filter.postId) {
                us = us.filter((e) => e.post_id === state.filter.postId);
            }

            if (state.filter.post) {
                us = us.filter((e) => {
                    const post = getters.getUserPostTitle(e.id);

                    return post
                        ? post
                            .toLowerCase()
                            .includes(state.filter.post.toLowerCase())
                        : false;
                });
            }

            if (state.filter.roleId) {
                us = us.filter((e) => e.users_roles.map((r) => r.role_id).includes(state.filter.roleId));
            }

            if (state.filter.surname) {
                us = us.filter((e) => e.surname
                    .toLowerCase()
                    .includes(state.filter.surname.toLowerCase()));
            }
            if (state.filter.name) {
                us = us.filter((e) => e.name
                    .toLowerCase()
                    .includes(state.filter.name.toLowerCase()));
            }
            if (state.filter.patronymic) {
                us = us.filter((e) => e.patronymic
                    .toLowerCase()
                    .includes(state.filter.patronymic.toLowerCase()));
            }
            if (state.filter.login) {
                us = us.filter((e) => e.login
                    .toLowerCase()
                    .includes(state.filter.login.toLowerCase()));
            }

            if (state.filter.dateVisitFrom) {
                const datePoint = this.$dayjs(state.filter.dateVisitFrom, "DD.MM.YYYY");

                us = us.filter((e) => e.last_visit_d >= datePoint);
            }
            if (state.filter.dateVisitTill) {
                const datePoint = this.$dayjs(state.filter.dateVisitTill, "DD.MM.YYYY").add(1, "day");

                us = us.filter((e) => !e.last_visit_d || e.last_visit_d <= datePoint);
            }

            return us.map((u) => {
                const accessLevel = Math.max(
                    0,
                    ...getters.roles
                        .filter((r) => u.users_roles.map((ur) => ur.role_id).includes(r.id))
                        .map((r) => r.access_level),
                );

                return {
                    ...u,
                    accessLevel,
                };
            });
        },
        usersHotline: (state) => {
            let us = state.users.filter((u) => u.hotline_worker === true);

            if (state.filterUsersHotline.surname) {
                us = us.filter((u) => u.surname
                    .toLowerCase()
                    .includes(state.filterUsersHotline.surname.toLowerCase()));
            }
            if (state.filterUsersHotline.phone) {
                us = us.filter((u) => (u.phone || "")
                    .toLowerCase()
                    .includes(state.filterUsersHotline.phone.toLowerCase()));
            }
            if (state.filterUsersHotline.email) {
                us = us.filter((u) => (u.email || "")
                    .toLowerCase()
                    .includes(state.filterUsersHotline.email.toLowerCase()));
            }
            if (state.filterUsersHotline.programs_ids.length > 0) {
                us = us.filter((u) => {
                    const upIds = u.users_programs.map((up) => up.program_id);

                    return upIds.reduce(
                        (a, pid) => (a || state.filterUsersHotline.programs_ids.includes(pid)),
                        false,
                    );
                });
            }

            return us;
        },
        filterUsers: (state) => state.filter,
        filterUsersHotline: (state) => state.filterUsersHotline,
        getUserFIO: (state) => (emplId) => {
            const empl = state.users.find((e) => e.id === emplId);
            return empl
                ? `${empl.surname} ${empl.name} ${empl.patronymic || ""}`
                : "";
        },
        getUserFIOshort: (state) => (emplId) => {
            const empl = state.users.find((e) => e.id === emplId);

            if (empl && empl.hotline_worker) {
                return `Исполнитель ТП ${empl.surname}`;
            }

            return empl
                ? `${empl.name[0]}.${empl.patronymic
                    ? `${empl.patronymic[0]}.`
                    : ""}${empl.surname}`
                : "";
        },
        getUserPostTitle: (state, getters) => (emplId) => {
            const empl = state.users.find((e) => e.id === emplId);

            if (!empl) {
                return "";
            }

            return empl.post_description || getters.postTitle(empl.post_id);
        },
        getUserDueOrder: (state) => (emplId) => {
            const empl = state.users.find((e) => e.id === emplId);
            return empl
                ? empl.due_order
                : "";
        },
        engineers: (state) => (
            // TODO: engineers sign
            state.users.filter(
                (e) => e.users_roles.map((ur) => ur.role_id).includes(2),
            )
        ),
    },
    actions: {
        getUsersFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                const filterL = {};

                axios.get(`${siteHost}/api/users/${JSON.stringify(filterL)}`)
                    .then((res) => {
                        // console.log(res.data.users);
                        commit("setUsers", res.data.users);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveUser({ dispatch, getters }, user) {
            const userUnits = getters.userUnits(user.id);

            return new Promise((resolve, reject) => {
                if (user.id > 0) {
                    axios.put(
                        `${siteHost}/api/users/${user.id}`,
                        {
                            user,
                            userUnits,
                        },
                    )
                        .then(() => {
                            dispatch("getUsersFromDB");
                            resolve("Пользователь сохранен");
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                }
                else {
                    axios.post(
                        `${siteHost}/api/users`,
                        {
                            user,
                            userUnits,
                        },
                    )
                        .then(() => {
                            // TODO: this is overhead, use res from server side
                            dispatch("getUsersFromDB");
                            dispatch("getUsersRolesFromDB");
                            dispatch("getUsersUnitsFromDB");
                            resolve("Пользователь создан");
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
                }
            });
        },
        deleteUser({ commit }, userId) {
            return new Promise((resolve, reject) => {
                if (userId > 0) {
                    axios.delete(`${siteHost}/api/users/${userId}`)
                        .then(() => {
                            commit("deleteUser", userId);
                            resolve("Пользователь удален");
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
                else {
                    reject(new Error("Ошибка"));
                }
            });
        },
        setFilterUsers({ commit }, filter) {
            commit("setFilterUsers", filter);
        },
        setFilterUsersHotline({ commit }, filter) {
            commit("setFilterUsersHotline", filter);
        },
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        deleteUser(state, userId) {
            const userIndex = state.users.findIndex((u) => u.id === userId);
            if (userIndex >= 0) {
                state.users.splice(userIndex, 1);
            }
        },
        setFilterUsers(state, filter) {
            state.filter = filter;
        },
        setFilterUsersHotline(state, filter) {
            state.filterUsersHotline = filter;
        },
    },
};
