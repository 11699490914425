<template>
    <q-select
        v-model="result"
        outlined
        dense
        class="cell-span-col-2"
        label="Результат исполнения"
        clearable
        :options="PERFORM_RESULTS_PO_COMMON"
        emitValue
        mapOptions
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
    <PoeDatePicker
        v-model="resultDate"
        dateFormat="YMD"
        outlined
        dense
        class="cell-span-col-2"
        label="Дата исполнения"
        emptyDateAllowed
        :minDate="new Date(request.request_date_add)"
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Время исполнения"
        :stackLabel="result_time !== null"
        color="dark"
        labelColor="grey-9"
        disable
    >
        <div>
            {{ result_time }}
        </div>
    </q-field>
    <q-input
        ref="resComment"
        v-model="note"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Выполненные работы"
        errorMessage="Необходимо заполнить"
        :error="!commentRules"
        lazyRules
        hideBottomSpace
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_STATUS_VALUES,
    PERFORM_RESULTS_PO_COMMON,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";
import { dateYMDtoDMY, dateDiffYMD } from "@/helpers/helpers";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";

export default {
    name: "Moving_2_Result", // eslint-disable-line
    components: {
        PoeDatePicker,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REQUEST_STATUS_VALUES,
            PERFORM_RESULTS_PO_COMMON,
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
        }),
        result: generateRequestProperty("result"),
        note: generateRequestProperty("note"),
        resultDate: generateRequestProperty("result_d"),
        commentRules() {
            return this.result
                ? this.note && this.note.length > 3
                : true;
        },
        result_time() {
            return dateDiffYMD(this.request.request_date_add, this.resultDate);
        },
    },
};
</script>
