<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <div
                v-if="showProgress"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    centerColor="grey-1"
                    showValue
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Проверка моделей учетных единиц
                </div>
            </q-card-section>

            <q-separator />

            <q-btn
                flat
                label="Проверить"
                color="primary"
                @click="checkToolsModels"
            />

            <div
                v-if="toolsModelsWithoutPictures.length > 0"
                class="dialog-common-grid-col-2"
            >
                <div class="cell cell-right">
                    Моделей без изображений:
                </div>
                <div class="cell">
                    {{ toolsModelsWithoutPictures.length }}
                </div>
                <div class="cell cell-span-col-2 cell-center">
                    <q-btn
                        flat
                        label="Искать изображения"
                        color="primary"
                        @click="fixToolsModels"
                    />
                </div>
            </div>

            <div
                v-if="checkResult"
                class="dialog-common-grid-col-2"
            >
                <div class="cell cell-right">
                    {{ checkResult }}
                </div>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
    name: "DialogCheckTools",
    data() {
        return {
            showSpinner: false,
            showProgress: false,
            valueProgress: 0,
            toolsModelsWithoutPictures: [],
            checkResult: "",
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async checkToolsModels() {
            this.showSpinner = true;
            const res = await axios.get(`${this.siteHost}/api/tools_models_without_pictures`);
            this.toolsModelsWithoutPictures = res.data.toolsModelsWithoutPictures;
            this.showSpinner = false;
        },
        async fixToolsModels() {
            this.valueProgress = 0;
            this.showProgress = true;

            let picturesCount = 0;

            for (let i = 0; i < this.toolsModelsWithoutPictures.length; i++) {
                const res = await axios.post(
                    `${this.siteHost}/api/tools_model_fix_pictures`,
                    {
                        modelId: this.toolsModelsWithoutPictures[i].id,
                    },
                );

                picturesCount += res.data.pictures;

                this.valueProgress = parseFloat(
                    ((i * 100) / this.toolsModelsWithoutPictures.length).toFixed(2),
                );
            }

            this.checkResult = `Найдено изображений: ${picturesCount}`;

            this.toolsModelsWithoutPictures = [];
            this.showProgress = false;
        },
    },
};
</script>
