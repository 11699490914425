<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Список заявок ({{ reqsCount }})
            <q-space />
            <FilterRequests />
            <q-btn
                round
                :icon="matSave"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Выгрузить текущий список заявок
                </q-tooltip>
                <q-menu autoClose>
                    <q-list>
                        <q-item
                            clickable
                            @click="createRequestsList('docx')"
                        >
                            <q-item-section>
                                В формате docx
                            </q-item-section>
                        </q-item>
                        <q-item
                            clickable
                            @click="createRequestsList('xlsx')"
                        >
                            <q-item-section>
                                В формате xlsx
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </div>

        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="reqsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>

        <div class="requests-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                ГЛ / Филиал
            </div>
            <div class="cell cell-header cell-center">
                Дата регистрации
            </div>
            <div class="cell cell-header cell-center">
                Номер
            </div>
            <div class="cell cell-header cell-center">
                Номер на ГЛ
                <q-tooltip
                    anchor="top middle"
                    self="center middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Номер на "Горячей Линии"
                </q-tooltip>
            </div>
            <div class="cell cell-header cell-center">
                Дата исполнения
            </div>
            <div class="cell cell-header cell-center">
                Организация
            </div>
            <div class="cell cell-header cell-center">
                Тип
            </div>
            <div class="cell cell-header cell-center">
                Состояние
            </div>
            <div class="cell cell-header cell-center">
                Содержание
            </div>
            <div class="cell cell-header" />
            <!-- data -->
            <div
                v-for="(request, requestIndex) in requests"
                :key="requestIndex"
                :class="[
                    'row-wrapper',
                    getColorClass(request.request_kind_id, request.request_status_id)
                ]"
                @click="startEdit(requestIndex)"
            >
                <div class="cell cell-center">
                    {{ (currentPage - 1) * 20 + requestIndex + 1 }}
                </div>
                <div class="cell cell-center">
                    <div
                        v-if="request.request_performer_kind === PERFORM_KIND.SENIOR"
                        class="hot-line-logo"
                    >
                        ГЛ
                    </div>
                </div>
                <div class="cell cell-center">
                    {{ $dayjs(request.request_date_add).format("DD.MM.YYYY") }}
                </div>
                <div class="cell cell-center cell-grid">
                    <div />
                    <div>
                        {{ request.number }}
                    </div>
                    <div>
                        <q-btn
                            v-if="allowEditNumber"
                            round
                            :icon="matAutorenew"
                            size="sm"
                            @click="(e) => changeNumber(e, requestIndex)"
                        />
                    </div>
                </div>
                <div class="cell cell-center">
                    {{ request.hotlinegas_number }}
                </div>
                <div class="cell cell-center">
                    {{ request.result_d
                        ? $dayjs(request.result_d).format("DD.MM.YYYY")
                        : "" }}
                </div>
                <div
                    class="cell"
                    v-html="formUnitName(request.unit_id, request.contact_id)"
                />
                <div class="cell cell-center">
                    {{ getReqKindTitle(request.request_kind_id) }}
                </div>
                <div class="cell cell-center">
                    {{ getReqStatusTitle(request.request_status_id) }}
                </div>
                <div class="cell">
                    {{ request.request_text }}
                </div>
                <div
                    class="cell cell-center"
                    style="cursor: default;"
                    @click="(event) => event.stopPropagation()"
                >
                    <q-btn
                        v-if="allowDelRequest"
                        round
                        :icon="matDelete"
                        size="sm"
                        class="poeso-table--control-button"
                        @click="deleteRequest(requestIndex)"
                    />
                </div>
            </div>
        </div>
        <div class="app-controls">
            <ButtonNewItem
                v-if="allowCreateRequest"
                buttonToolTip="Создать заявку"
                @startEdit="startEdit"
            />
        </div>
    </div>
    <div
        v-if="showSpinner"
        class="req-dialog-cover"
    >
        <q-spinner-grid
            color="primary"
            size="5.5em"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
    PERFORM_KIND,
    REQUEST_KINDS_VALUES,
    RIGHTS_CONSTANTS,
} from "@/store/constants";

import FilterRequests from "@/components/Filters/FilterRequests.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import ButtonNewItem from "@/components/Common/ButtonNewItem.vue";
import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import DialogChangeRequestNumber from "@/components/Dialogs/DialogChangeRequestNumber.vue";

export default {
    name: "Requests",
    components: {
        FilterRequests,
        ButtonNewItem,
    },
    setup() {
        return {
            PERFORM_KIND,
            REQUEST_KINDS_VALUES,
        };
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            requests: "requests",
            getReqKindTitle: "getReqKindTitle",
            getReqStatusTitle: "getReqStatusTitle",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
            reqsPagesCount: "reqsPagesCount",
            reqsPageNumber: "reqsPageNumber",
            currentUser: "currentUser",
            unitsFull: "unitsFull",
            reqsCount: "reqsCount",
        }),
        currentPage: {
            get() {
                return this.reqsPageNumber;
            },
            set(value) {
                this.$store.dispatch("setReqsPageNumber", value);
            },
        },
        allowCreateRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_CREATE &
                (RIGHTS_CONSTANTS.OWN | RIGHTS_CONSTANTS.ALL));
        },
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
        allowEditNumber() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_EDIT_NUMBER & RIGHTS_CONSTANTS.FULL);
        },
    },
    mounted() {
        this.$store.dispatch("cleanReqsFilter");
    },
    methods: {
        getColorClass(requestKindId, requestStatusId) {
            if (requestKindId === REQUEST_KINDS_VALUES.MISTAKE ||
                requestKindId === REQUEST_KINDS_VALUES.REVOKE) {
                return "request-kind__mistake";
            }
            return `request-status__${requestStatusId}`;
        },
        startEdit(index) {
            const sel = window.getSelection();
            if (sel.type === "Range") {
                return;
            }

            let requestForEdit = {};
            if (index !== undefined) {
                requestForEdit = JSON.parse(JSON.stringify(this.requests[index]));
            }

            this.$q.dialog({
                component: DialogEditRequest,
                componentProps: {
                    requestForEdit,
                },
            });
        },
        deleteRequest(index) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить заявку № ${this.requests[index].number}?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteRequest", this.requests[index].id)
                        .then(() => {
                            this.$q.notify({
                                type: "notify-success",
                                message: "Заявка удалена",
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        formUnitName(unitId, contactId) {
            const emplPostTitle = this.getUserPostTitle(contactId);
            const unit = this.unitsFull.find((u) => u.id === unitId);
            return [
                unit ? unit.name : "",
                unit ? unit.address : "",
                this.getUserFIO(contactId),
                emplPostTitle ? `${emplPostTitle}` : "",
            ].join("<br>");
        },
        createRequestsList(docType) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Формирование документа может занять продолжительное время",
                    labelOk: "Продолжить",
                    labelCancel: "Отменить",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "getAllRequestsFromDB",
                        docType,
                    );
                });
        },
        changeNumber(event, index) {
            event.stopPropagation();
            this.$q.dialog({
                component: DialogChangeRequestNumber,
                componentProps: {
                    request: this.requests[index],
                },
            });
        },
    },
};
</script>
