<template>
    <div class="cell-span-col-2">
        Для заявок на ремонт оборудования
    </div>
    <q-select
        v-model="setId"
        outlined
        dense
        :options="toolsSetsNamesCodes"
        optionValue="id"
        optionLabel="name"
        mapOptions
        emitValue
        label="Вид комплекта оборудования"
        clearable
        class="cell-start-row"
    />
    <q-select
        v-model="toolKindId"
        outlined
        dense
        :options="toolsKinds"
        optionValue="id"
        optionLabel="title"
        mapOptions
        emitValue
        label="Вид оборудования"
        clearable
    />

    <q-select
        v-model="repairKindId"
        outlined
        dense
        :options="GUARANTY_TYPES"
        mapOptions
        emitValue
        label="Тип гарантийности оборудования"
        clearable
        class="cell-start-row"
    />
    <q-select
        v-model="repairType"
        :options="REPAIRS_TYPES"
        emitValue
        mapOptions
        outlined
        dense
        label="Вид ремонта"
        clearable
    />
    <q-select
        v-model="viewBefore"
        outlined
        dense
        label="Результат осмотра до ремонта"
        :options="OPTIONS_VEIW_BEFORE"
        emitValue
        mapOptions
        clearable
    />
    <q-select
        v-model="viewAfter"
        outlined
        dense
        label="Результат осмотра после ремонта"
        :options="OPTIONS_VIEW_AFTER"
        emitValue
        mapOptions
        clearable
    />
    <q-select
        v-model="repairResult"
        :options="REPAIRS_RESULTS"
        emitValue
        mapOptions
        outlined
        dense
        label="Результаты ремонта"
        clearable
    />
    <PoeDatePicker
        v-model="dateToolIn"
        dateFormat="YMD"
        labelTitle="Дата поступления оборудования в филиал"
        emptyDateAllowed
        classOuter="col"
        :futureDateAllowed="false"
    />
    <PoeDatePicker
        v-model="dateToolOut"
        dateFormat="YMD"
        labelTitle="Дата выбытия оборудования из филиала"
        emptyDateAllowed
        classOuter="col"
        :futureDateAllowed="false"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_KINDS,
    GUARANTY_TYPES,
    OPTIONS_VEIW_BEFORE,
    OPTIONS_VIEW_AFTER,
    REPAIRS_RESULTS,
    REPAIRS_TYPES,
    // REQUESTS_CATEGORIES,
    // PROBLEMS_CATEGORIES,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";

function generateProperty(property) {
    return {
        get() {
            return this.filterRequestsRepair[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setFilterRequestsRepair",
                {
                    ...this.filterRequestsRepair,
                    [property]: v,
                },
            );
        },
    };
}

function generatePropertyTool(property) {
    return {
        get() {
            return this.filterRequestsRepairTool[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setFilterRequestsRepairTool",
                {
                    ...this.filterRequestsRepairTool,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterRequestsRepair",
    components: {
        PoeDatePicker,
    },
    setup() {
        return {
            REQUEST_KINDS_VALUES,
            REQUEST_KINDS,
            GUARANTY_TYPES,
            OPTIONS_VEIW_BEFORE,
            OPTIONS_VIEW_AFTER,
            REPAIRS_RESULTS,
            REPAIRS_TYPES,
            // REQUESTS_CATEGORIES,
            // PROBLEMS_CATEGORIES,
        };
    },
    computed: {
        ...mapGetters({
            filterRequestsRepair: "filterRequestsRepair",
            filterRequestsRepairTool: "filterRequestsRepairTool",

            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            toolsKinds: "toolsKinds",
        }),
        setId: generatePropertyTool("set_id"),
        toolKindId: generatePropertyTool("tool_kind_id"),

        repairKindId: generateProperty("repair_kind_id"),
        viewBefore: generateProperty("view_res_before"),
        viewAfter: generateProperty("view_res_after"),
        repairType: generateProperty("repair_type_id"),
        repairResult: generateProperty("repair_result_id"),
        dateToolIn: generateProperty("tool_in_d"),
        dateToolOut: generateProperty("tool_out_d"),
    },
};
</script>
