<template>
    <div :class="doWorkWithSigns ? 'documents-grid-signs' : 'documents-grid'">
        <!-- header -->
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Вложения
        </div>
        <div class="cell cell-header" />
        <div
            v-if="doWorkWithSigns"
            class="cell cell-header"
        />
        <div
            v-if="doWorkWithSigns"
            class="cell cell-header"
        />
        <div
            v-if="doWorkWithSigns"
            class="cell cell-header"
        />
        <div
            v-if="doWorkWithSigns"
            class="cell cell-header"
        />
        <div
            v-if="doWorkWithSigns"
            class="cell cell-header"
        />
        <div class="cell cell-header cell-center">
            <q-btn
                round
                :icon="matAdd"
                size="sm"
                color="primary"
                :disable="readonly"
                @click="addDoc"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Добавить документ
                </q-tooltip>
            </q-btn>
        </div>
        <!-- data -->
        <DocumentsRow
            v-for="(file, index) in filesList"
            :key="index"
            :file="file"
            :orderN="index + 1"
            :doWorkWithSigns="doWorkWithSigns"
            :readonly="readonly"
            @saveDocAsFile="saveDocAsFile"
            @saveBlobAsFile="saveBlobAsFile"
            @saveDocAsZipWithSigns="saveDocAsZipWithSigns"
            @delDoc="delDoc"
        />
    </div>
    <input
        id="hiddenFileInputId"
        type="file"
        name="hiddenFileInputId"
        multiple
        style="display: none;"
        :accept="fileTypes.join(',')"
        @change="filesListChanged"
    >
</template>

<script>
import axios from "axios";
import JSzip from "jszip";
import { saveAs } from "file-saver";
import DocumentsRow from "./DocumentsRow.vue";

export default {
    name: "Documents",
    components: {
        DocumentsRow,
    },
    props: {
        fileTypes: {
            type: Array,
            default: () => [
                ".png",
                ".jpeg",
                ".jpg",
                ".pdf",
                ".doc",
                ".docx",
                ".odt",
                ".xls",
                ".xlsx",
                ".zip",
                ".rar",
                ".7z",
            ],
        },
        maxFileSize: {
            type: Number,
            default: 52428800, // 50Mb
        },
        maxFilesCount: {
            type: Number,
            default: 5,
        },
        fileSection: {
            type: String,
            default: "request",
        },
        addDocToList: {
            type: Function,
            required: true,
        },
        deleteDoc: {
            type: Function,
            required: true,
        },
        filesList: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        doWorkWithSigns: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addDoc() {
            const fileInput = document.getElementById("hiddenFileInputId");
            fileInput.click();
        },
        delDoc(index) {
            this.deleteDoc(index);
        },
        saveDocAsFile(filePath, fileName) {
            saveAs(`${this.siteHost}/${filePath}`, fileName);
        },
        saveBlobAsFile(blob, fileName) {
            saveAs(blob, fileName);
        },
        async saveDocAsZipWithSigns(filePath, fileName, file) {
            const jszip = new JSzip();
            const originalFile = await axios.get(
                `${this.siteHost}/${filePath}`,
                {
                    responseType: "blob",
                },
            );
            jszip.file(fileName, originalFile.data);

            for (let iS = 0; iS < file.request_file_signers.length; iS++) {
                const signContent = await axios.get(
                    `${this.siteHost}/${file.request_file_signers[iS].path}`,
                    {
                        responseType: "blob",
                    },
                );
                jszip.file(`${fileName}_sign_${file.request_file_signers[iS].signer_kind}.sign`, signContent.data);
            }

            jszip.generateAsync(
                { type: "blob" },
            )
                .then((contentNew) => {
                    saveAs(contentNew, `${fileName}_signs.zip`);
                });
        },
        filesListChanged(e) {
            // TODO: check if file exists
            if (this.filesList.length + e.target.files.length > this.maxFilesCount) {
                this.$q.notify({
                    type: "notify-failure",
                    message: `Не более ${this.maxFilesCount} файлов`,
                });
                return;
            }

            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];

                if (file.size > this.maxFileSize) {
                    const sizeMb = this.maxFileSize / (1024 * 1024);
                    this.$q.notify({
                        type: "notify-failure",
                        message: `Размер файла не может быть более ${sizeMb} мегабайт`,
                    });
                    return;
                }

                const ext = `.${file.name.split(".").pop().toLowerCase()}`;

                if (!this.fileTypes.includes(ext)) {
                    this.$q.notify({
                        type: "notify-failure",
                        message: "Неподдерживаемый тип файла",
                    });
                    return;
                }
            }

            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                this.addDocToList({
                    file_name: file.name,
                    mimetype: file.type,
                    section: this.fileSection,
                    blob: file,
                });
            }
        },
        mimeType(fileName) {
            if (fileName.endsWith(".png")) {
                return "image/png";
            }
            if (fileName.endsWith(".jpeg")) {
                return "image/jpeg";
            }
            if (fileName.endsWith(".jpg")) {
                return "image/jpeg";
            }
            if (fileName.endsWith(".pdf")) {
                return "application/pdf";
            }
            if (fileName.endsWith(".doc")) {
                return "application/msword";
            }
            if (fileName.endsWith(".sig")) {
                return "text/plain";
            }

            return "text/html";
        },
    },
};
</script>
