<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Организация
                </div>
            </q-card-section>

            <q-tabs
                :modelValue="tabCurrent"
                dense
                activeColor="primary"
                indicatorColor="primary"
                align="justify"
                @update:modelValue="tabChange"
            >
                <q-tab
                    name="info"
                    label="Реквизиты"
                />
                <q-tab
                    name="buildings"
                    label="Объекты"
                />
            </q-tabs>

            <q-separator />

            <q-tab-panels
                v-model="tabCurrent"
                animated
            >
                <q-tab-panel name="info">
                    <div class="organization-edit-grid dialog-section">
                        <SelectFilter
                            v-model="org.region_id"
                            :options="regions"
                            label="Регион"
                            class="cell-span-col-2"
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            ref="refName"
                            v-model="org.name"
                            outlined
                            dense
                            label="Наименование"
                            class="cell-span-col-2"
                            :rules="[val => val && val.length > 0 || 'Укажите наименование']"
                            hideBottomSpace
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            v-model="org.fullname"
                            outlined
                            dense
                            label="Полное наименование"
                            class="cell-span-col-2"
                            :readonly="!allowEditOrganization"
                        />
                        <q-select
                            v-model="org.kind_id"
                            outlined
                            dense
                            :options="orgKinds"
                            optionValue="id"
                            optionLabel="title"
                            mapOptions
                            emitValue
                            label="Тип организации"
                            class="cell-span-col-2"
                            :readonly="!allowEditOrganization"
                        />
                        <q-checkbox
                            v-model="org.is_host"
                            :disable="filterOrg.is_host || !allowEditOrganization"
                            label="Балансодержатель"
                            class="cell-span-col-2"
                        />
                        <q-select
                            v-if="!org.is_host"
                            v-model="org.host_id"
                            outlined
                            dense
                            :options="hostsFiltered"
                            optionValue="id"
                            optionLabel="name"
                            mapOptions
                            emitValue
                            label="Балансодержатель"
                            class="cell-span-col-2"
                            clearable
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            v-model="org.phone"
                            outlined
                            dense
                            label="Телефон"
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            v-model="org.mail_address"
                            outlined
                            dense
                            label="Email"
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            ref="refVncode"
                            v-model="org.vncode"
                            outlined
                            dense
                            label="VNCODE"
                            class="cell-span-col-2"
                            :rules="[val => val && val.length > 0 || 'Укажите VNCODE']"
                            hideBottomSpace
                            :readonly="!allowEditOrganization"
                        />
                        <q-input
                            v-if="isFilial"
                            ref="refVncode"
                            v-model="org.assignment"
                            outlined
                            dense
                            label="В соответствии с документом"
                            autogrow
                            class="cell-span-col-2"
                            :readonly="!allowEditOrganization"
                        />
                        <template v-if="allowEditHotlinePass">
                            <q-input
                                v-model="org.hot_line_email"
                                outlined
                                dense
                                label="Email / логин на горячей линии"
                                :readonly="!allowEditOrganization"
                            />
                            <q-input
                                v-model="org.hot_line_password"
                                outlined
                                dense
                                label="Пароль на горячей линии"
                                :readonly="!allowEditOrganization"
                            />
                        </template>
                    </div>
                </q-tab-panel>
                <q-tab-panel name="buildings">
                    <q-card-section
                        style="max-height: 70vh; height: 60vh;"
                        class="scroll no-padding"
                    >
                        <div class="simple-three-grid">
                            <div class="cell cell-header cell-center">
                                №
                            </div>
                            <div class="cell cell-header cell-center">
                                Адрес
                            </div>
                            <div class="cell cell-header cell-center">
                                <q-btn
                                    v-if="allowEditOrganization"
                                    round
                                    :icon="matAdd"
                                    size="sm"
                                    color="primary"
                                    @click="addUnit"
                                >
                                    <q-tooltip
                                        :delay="300"
                                        anchor="top right"
                                        self="bottom middle"
                                        class="bg-amber text-body2 text-black shadow-4"
                                    >
                                        Добавить объект
                                    </q-tooltip>
                                </q-btn>
                            </div>
                            <div
                                v-for="(unit, index) in units"
                                :key="index"
                                class="row-wrapper"
                            >
                                <div class="cell cell-center">
                                    {{ index + 1 }}
                                </div>
                                <div class="cell">
                                    <q-input
                                        v-model="units[index].address"
                                        outlined
                                        dense
                                        label="Адрес"
                                        :readonly="!allowEditOrganization"
                                    />
                                </div>
                                <div class="cell cell-center">
                                    <q-btn
                                        v-if="allowEditOrganization"
                                        round
                                        :icon="matDelete"
                                        size="sm"
                                        class="poeso-table--control-button"
                                        @click="() => removeUnit(index)"
                                    />
                                </div>
                            </div>
                        </div>
                    </q-card-section>
                </q-tab-panel>
            </q-tab-panels>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="allowEditOrganization"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import { RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "DialogEditOrganization",
    components: {
        SelectFilter,
    },
    props: {
        organization: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            org: {},
            tabCurrent: "info",
            units: [],
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            filterOrg: "filterOrg",
            hosts: "hosts",
            regions: "regions",
            getUnitsOfOrg: "getUnitsOfOrg",
            orgKinds: "orgKinds",
            currentUser: "currentUser",
        }),
        isFilial() {
            const ok = this.orgKinds.find((orgKind) => orgKind.id === this.org.kind_id);
            return ok
                ? ok.is_filial
                : false;
        },
        hostsFiltered() {
            return this.org.region_id
                ? this.hosts.filter((h) => h.region_id === this.org.region_id)
                : this.hosts;
        },
        allowEditOrganization() {
            return !!(this.currentUser.rightsObject.RIGHT_ORGANIZATIONS & RIGHTS_CONSTANTS.FULL);
        },
        allowEditHotlinePass() {
            return !!(this.currentUser.rightsObject.RIGHT_HOTLINE_PASS & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                },
                false,
            );

            return hasErrors;
        },
        addUnit() {
            this.units.push({
                id: null,
                organization_id: this.org.id,
                address: "",
            });
        },
        removeUnit(unitIndex) {
            this.units.splice(unitIndex, 1);
        },
        tabChange(value) {
            if (this.$refs.refName && this.checkErrorsInfo()) {
                this.tabCurrent = "info";
            }
            else {
                this.tabCurrent = value;
            }
        },
        show() {
            this.org = { ...this.organization };
            this.units = JSON.parse(JSON.stringify(this.getUnitsOfOrg(this.org.id)));
            this.tabCurrent = "info";
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            if (this.tabCurrent === "info" && this.checkErrorsInfo()) return;
            if (this.units.length < 1) {
                this.tabCurrent = "buildings";
                this.$q.notify({
                    type: "notify-failure",
                    message: "Нужно ввести хотя бы одно здание",
                });
                return;
            }

            this.showSpinner = true;

            this.$store.dispatch(
                "saveOrganization",
                {
                    org: this.org,
                    units: this.units,
                },
            )
                .then((message) => {
                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });
                    this.hide();
                })
                .catch((errMessage) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.showSpinner = false;
                });
        },
    },
};
</script>
