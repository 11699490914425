<template>
    <div class="request-hotline-grid">
        <div class="cell-span-col-2">
            <q-btn
                filled
                color="primary"
                label="Принять к исполнению"
                :disable="!allowTakeForPerform"
                @click="takeForPerform"
            />
        </div>
        <q-select
            v-model="hotlineResultId"
            :options="PERFORM_RESULTS_PO_COMMON"
            emitValue
            mapOptions
            outlined
            dense
            label="Результат исполнения"
            class="cell-span-col-6"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <q-select
            v-model="hotlineResultType"
            :options="PERFORM_TYPES"
            emitValue
            mapOptions
            outlined
            dense
            label="Вид исполнения"
            class="cell-span-col-2"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
            @update:modelValue="setPerformDate"
        />
        <PoeDatePicker
            v-model="hotlineResultDate"
            outlined
            dense
            class="cell-span-col-2"
            label="Дата исполнения"
            dateFormat="YMD"
            emptyDateAllowed
            :minDate="new Date(request.request_date_add)"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <q-field
            outlined
            dense
            label="Время исполнения"
            :stackLabel="!!duration"
            color="dark"
            labelColor="grey-9"
            class="cell-span-col-2"
        >
            <div class="field-inner">
                {{ duration }}
            </div>
        </q-field>
        <q-input
            v-model="hotlineWorkDescription"
            outlined
            dense
            label="Описание выполненной работы"
            type="textarea"
            class="cell-span-col-6"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <template v-if="allowDelRequest">
            <q-btn
                filled
                color="primary"
                label="Назначить исполнителя/исполнителей"
                class="cell-span-col-2"
                :disable="!canBeEdited"
                @click="chooseEngineer"
            />
            <q-btn
                filled
                color="primary"
                label="Заявка выполнена"
                :disable="readOnly || !canBeEdited"
                class="cell-span-col-2"
                @click="requestFulfil"
            />
            <q-btn
                filled
                color="primary"
                label="На повторное исполнение"
                :disable="!canBeEdited"
                @click="requestRevive"
            />
            <q-btn
                filled
                color="primary"
                label="Отменить исполнение"
                :disable="disableResume || !canBeEdited"
                @click="resumeRequest"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import {
    PERFORM_RESULTS_PO_COMMON,
    PERFORM_RESULTS_IDS,
    REQUEST_STATUS_VALUES,
    PERFORM_TYPES,
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import { dateYMDtoDMY, dateDiffYMD } from "@/helpers/helpers";
import DialogSearchEngineer from "@/components/Dialogs/DialogSearchEngineer.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import generateRequestProperty from "@/helpers/generateRequestProperty";

export default {
    name: "DialogEditRequestHotline",
    components: {
        PoeDatePicker,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            PERFORM_RESULTS_PO_COMMON,
            REQUEST_STATUS_VALUES,
            PERFORM_TYPES,
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            request: "request",
            requestEngineers: "requestEngineers",
        }),
        requestStatusId: generateRequestProperty("request_status_id"),
        result: generateRequestProperty("result"),
        resultType: generateRequestProperty("result_type"),
        resultDate: generateRequestProperty("result_d"),
        note: generateRequestProperty("note"),
        hotlineResultId: generateRequestProperty("hotline_result_id"),
        hotlineResultType: generateRequestProperty("hotline_result_type"),
        hotlineResultDate: {
            get() {
                return date.formatDate(this.request.hotline_result_date, "YYYY-MM-DD");
            },
            async set(v) {
                let value;

                if (!v) {
                    value = null;
                }
                else {
                    value = typeof v === "string"
                        ? date.extractDate(v, "YYYY-MM-DD")
                        : v;
                }

                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "hotline_result_date",
                        value,
                    },
                );
            },
        },
        hotlineWorkDescription: generateRequestProperty("hotline_work_description"),
        duration() {
            if (this.hotlineResultDate) {
                return dateDiffYMD(this.request.request_date_add, this.hotlineResultDate);
            }

            return "";
        },
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
        readOnly() {
            return ![
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
            ].includes(this.requestStatusId);
        },
        disableResume() {
            const expiringDate = date.subtractFromDate(
                Date.now(),
                { days: 3 },
            );

            return (
                this.request.request_status_id !== REQUEST_STATUS_VALUES.CLOSED ||
                !(this.currentUser.rightsObject.RIGHT_REQUEST_RESUME & RIGHTS_CONSTANTS.FULL) ||
                this.request.result_d < expiringDate
            );
        },
        allowTakeForPerform() {
            return (
                this.request.request_status_id === REQUEST_STATUS_VALUES.HOT_LINE_WAITING &&
                this.requestEngineers.includes(this.currentUser.id)
            );
        },
    },
    methods: {
        takeForPerform() {
            this.$store.dispatch("setRequestEngineers", [this.currentUser.id]);
            this.requestStatusId = REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING;
        },
        requestRevive() {
            this.requestStatusId = REQUEST_STATUS_VALUES.HOT_LINE_WAITING;
            this.result = null;
            this.hotlineResultDate = null;
        },
        chooseEngineer() {
            this.$q.dialog({
                component: DialogSearchEngineer,
                componentProps: {
                    dialogTitle: "Выбор исполнителя/исполнителей ТП",
                    workerType: "hotlineWokrer",
                },
            })
                .onOk((ids) => {
                    this.$store.dispatch("setRequestEngineers", ids);
                });
        },
        requestFulfil() {
            this.hotlineResultId = PERFORM_RESULTS_IDS.FULFILED;
            this.result = PERFORM_RESULTS_IDS.FULFILED_HOTLINE;
            this.resultType = this.hotlineResultType;
            this.hotlineResultDate = new Date();
            this.resultDate = date.formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
            this.note += this.hotlineWorkDescription;
        },
        resumeRequest() {
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "request_status_id",
                    value: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
                },
            );
        },
        setPerformDate() {
            this.hotlineResultDate = new Date();
        },
    },
};
</script>
