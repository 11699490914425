<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета (Приложение №2)
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <SelectFilter
                    ref="refUnit"
                    v-model="hostId"
                    :options="hostsFiltered"
                    optionLabel="name"
                    label="Балансодержатель"
                    clearable
                    class="q-mb-xs"
                    hideBottomSpace
                    :rules="[val => val || 'Нужно выбрать организацию']"
                    :disable="allRegions"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import {
    DATE_LOCALE_2,
} from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import { getRequestsEnclosure2 } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateEnclosure2",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            hostId: null,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            hosts: "hosts",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getUnitsOfOrg: "getUnitsOfOrg",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            unitsFull: "unitsFull",
            currentUser: "currentUser",
            regions: "regions",
        }),
        hostsFiltered() {
            return this.hosts.filter((u) => u.region_id === this.regionId);
        },
    },
    watch: {
        regionId() {
            this.hostId = null;
        },
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refUnit.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refUnit.hasError && !this.allRegions)) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            const unitsIds = this.allRegions
                ? this.unitsFull
                    .map((u) => u.id)
                : this.unitsFull
                    .filter((u) => u.host_id === this.hostId)
                    .map((u) => u.id);
            const unitsOfOrg = this.getUnitsOfOrg(this.hostId);

            for (let i = 0; i < unitsOfOrg.length; i++) {
                if (unitsIds.includes(unitsOfOrg[i].id)) {
                    const first = unitsOfOrg[i].id;
                    unitsIds.sort((x, y) => {
                        if (x === first) {
                            return -1;
                        }
                        return y === first ? 1 : 0;
                    });
                }
            }

            // TODO: send all units ids in one request
            let requests = [];
            for (let i = 0; i < unitsIds.length; i++) {
                this.valueProgress = Math.round((i / unitsIds.length) * 100);
                requests = requests.concat(
                    (await getRequestsEnclosure2({
                        unitId: unitsIds[i],
                        date_beg: this.dateStart,
                        date_end: this.dateFinsh,
                    })).requests,
                );
            }

            const outFileName = `Приложение №2 за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;
            let headerSuffix = "";
            if (!this.allRegions) {
                const regionId = this.getOrgProperty(this.hostId, "region_id");
                const orgKindCode = this.getOrgKindCode(this.getOrgProperty(this.hostId, "kind_id"));
                headerSuffix = `/ ${regionId} / ${orgKindCode}`;
            }

            const replacement = {
                header_suffix: headerSuffix,
                date_start: this.dateStart,
                date_finsh: this.dateFinsh,
                period_year: new Date(this.dateStart).getFullYear(),
                current_year: new Date().getFullYear(),
                items: await this.getRequestsData(requests),
                co3_count: requests.reduce((a, r) => a + +(r.co_3_number != null), 0),
                leaves_count: requests.reduce((a, r) => a + +(r.result_type === 2), 0),
                requests_count: requests.length,
            };

            createXls(
                this.siteHost,
                "enclosure-2.xlsx",
                outFileName,
                replacement,
            )
                .then((report) => {
                    saveXls(report);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        getOrgKindCode(orgKindId) {
            let code = "__";
            if (orgKindId === 6) {
                code = "В";
            }
            if (orgKindId === 13) {
                code = "О";
            }
            if (orgKindId === 3) {
                code = "У";
            }
            if (orgKindId === 4) {
                code = "КСОЮ";
            }
            if (orgKindId === 10) {
                code = "АСОЮ";
            }
            if (orgKindId === 12) {
                code = "КВС";
            }
            if (orgKindId === 11) {
                code = "АС";
            }
            return code;
        },
        async getRequestsData(requests) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                const co3_date = requests[i].co_3_d
                    ? date.formatDate(
                        requests[i].co_3_d,
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    )
                    : "";

                const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                const regionId = this.getOrgProperty(orgId, "region_id");
                const fullRegionName = this.getRegionName(regionId);
                const regionName = fullRegionName.includes(".") ? fullRegionName.split(".")[1].trim() : fullRegionName;
                const orgName = this.getOrgProperty(orgId, "name");
                const item = {
                    region_code: regionId,
                    region_name: regionName,
                    org_name: orgName,
                    failure_cause: requests[i].request_text || "",
                    performed_work: requests[i].note || "",
                    co3_num: requests[i].co_3_number || "",
                    co3_date,
                };
                items.push(item);
            }
            return items;
        },
    },
};
</script>
