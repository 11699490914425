<template>
    <div class="request-history">
        <div
            v-for="h in headers"
            :key="h"
            class="cell cell-header cell-center"
        >
            {{ h }}
        </div>

        <div
            v-for="(event, index) in history"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ $dayjs(event.date_time_d).format("DD.MM.YYYY HH:mm") }}
            </div>
            <div class="cell">
                {{ getUserFIO(event.user_id) }}
            </div>
            <div class="cell">
                {{ requestAttrHuman(event.request_attr) }}
            </div>
            <div class="cell">
                {{ eventValueHuman(event.request_attr, event.value_old) }}
            </div>
            <div class="cell">
                {{ eventValueHuman(event.request_attr, event.value_new) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_STATUSES,
    // REQUESTS_CATEGORIES,
    // PROBLEMS_CATEGORIES,
    REQUEST_KINDS,
    PERFORM_RESULTS_PO_SPECIAL,
    OPTIONS_VEIW_BEFORE,
    OPTIONS_VIEW_AFTER,
    REPAIRS_TYPES,
    PERFORM_TYPES,
} from "@/store/constants";

export default {
    name: "DialogEditRequestHistory",
    props: {
        history: {
            type: Array,
            required: true,
        },
    },
    setup() {
        return {
            headers: [
                "№",
                "Дата",
                "Пользователь",
                "Поле заявки",
                "Старое значение",
                "Новое значение",
            ],
            // TODO: simplify (title is not needed)
            requestAttrs: {
                request_kind_id: "Тип заявки",
                request_date_add: "Дата создания заявки",
                number: "Номер заявки",
                unit_id: "Объект",
                contact_id: "Контактное лицо",
                request_text: "Описание неисправности (проблемы)",
                request_status_id: "Статус",
                hotlinegas_number: "Исходящий номер",
                tool_id: "Оборудование",
                number_out: "Исходящий номер",
                date_out_d: "Дата",
                program_id: "Вид ПО",
                repair_type_id: "Вид ремонта",
                repair_result_id: "Результат ремонта",
                date_repair_d: "Дата ремонта",
                // perform_result_id: "Результат исполнения (2)",
                service_repair_id: "Сервис по ремонту",
                date_pass_d: "Дата отправки на ремонт",
                date_return_d: "Дата возврата из ремонта",
                result: "Результат исполнения",
                result_type: "Вид исполнения",
                result_d: "Дата исполнения",
                note: "Комментарий",
                co_3_d: "Дата акта СО-3",
                co_3_number: "Номер акта СО-3",
                co_7_d: "Дата акта СО-7",
                co_7_number: "Номер акта СО-7",
                co_8_d: "Дата акта СО-8",
                co_8_number: "Номер акта СО-8",
                co_41_number: "Номер акта СО-4.1",
                co_41_d: "Дата акта СО-4.1",
                view_res_before: "Результат осмотра до ремонта",
                view_res_after: "Результат осмотра после ремонта",
                comment_before: "Комментарий после осмотра до ремонта",
                comment_after: "Комментарий после осмотра после ремонта",
                tool_in_d: "Дата поступления оборудования в филиал",
                tool_out_d: "Дата выбытия оборудования из филиала",
                troubles_display: "Описание проявления неисправности",
                troubles_list: "Установлены следующие неисправности ПТС",
                request_text_expl: "Описание изученной заявки",
                engineer_id: "Исполнитель (инженер)",
            },
        };
    },
    computed: {
        ...mapGetters({
            getUserFIO: "getUserFIO",
        }),
    },
    methods: {
        requestAttrHuman(attr) {
            return this.requestAttrs[attr] || attr;
        },
        eventValueHuman(attr, value) {
            if (attr === "request_status_id") {
                const res = REQUEST_STATUSES.find((rs) => rs.id === +value);
                return res ? res.title : "Не установлено";
            }
            if (attr === "result") {
                const res = PERFORM_RESULTS_PO_SPECIAL.find((r) => r.value === +value);
                return res ? res.label : "Ожидание";
            }
            if (attr === "result_type") {
                const res = PERFORM_TYPES.find((r) => r.value === +value);
                return res ? res.label : "Не задано";
            }
            if (attr === "request_kind_id") {
                const res = REQUEST_KINDS.find((rs) => rs.id === +value);
                return res ? res.title : "Не установлено";
            }
            if (attr === "view_res_before") {
                if (!value) {
                    return "";
                }
                const res = OPTIONS_VEIW_BEFORE.find((rs) => rs.value === +value);
                return res ? res.label : "Не установлено";
            }
            if (attr === "view_res_after") {
                if (!value) {
                    return "";
                }
                const res = OPTIONS_VIEW_AFTER.find((rs) => rs.value === +value);
                return res ? res.label : "Не установлено";
            }
            if (attr === "repair_type_id") {
                if (!value) {
                    return "";
                }
                const res = REPAIRS_TYPES.find((rs) => rs.value === +value);
                return res ? res.label : "Не установлено";
            }
            if (attr === "engineer_id") {
                return this.getUserFIO(+value);
            }

            return value;
        },
    },
};
</script>
