import axios from "axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import {
    REQUEST_KINDS,
    REQUEST_STATUSES,
    siteHost,
    RIGHTS_CONSTANTS,
    PERFORM_KIND,
    CONTACT_KIND,
    REQUEST_STATUS_VALUES,
} from "@/store/constants";

import { saveRequestsToDocx } from "../helpers/saveDataToDocx";
import { saveRequestsToXlsx } from "../helpers/saveDataToXlsx";

export const cleanFilterState = {
    date_beg: `01.01.${(new Date()).getFullYear()}`,
    date_end: `31.12.${(new Date()).getFullYear()}`,
    request_kinds_ids: [],
    request_statuses_ids: [],
    units_ids: [],
    request_performer_kind: null,
    unsigned_docs: false,
    hotline_reqs_without_number: false,
    hotline_reqs_not_register: false,
    hotline_reqs_not_closed: false,
};

function saveRequestToDB(
    request,
    requestEngineers,
    reqWorks,
    reqSpares,
    filesList,
    requestDocs,
) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append(
            "request",
            JSON.stringify(request),
        );
        formData.append("engineers", JSON.stringify(requestEngineers));
        formData.append("works", JSON.stringify(reqWorks));
        formData.append("spares", JSON.stringify(reqSpares));

        formData.append("section", "request");

        formData.append(
            "filesList",
            JSON.stringify(
                filesList
                    .map((file) => ({ path: file.path }))
                    .filter((e) => e.path),
            ),
        );

        filesList.forEach((file) => {
            if (file.blob) {
                formData.append("request", file.blob);
            }
        });

        formData.append("section", "requestDocs");
        // list of old files
        formData.append(
            "oldDocsList",
            JSON.stringify(
                requestDocs
                    .map((file) => ({ path: file.path }))
                    .filter((e) => e.path),
            ),
        );
        // files themselves
        const newDocsList = [];
        requestDocs.forEach((file) => {
            if (file.blob) {
                formData.append("requestDocs", file.blob);
                newDocsList.push(file);
            }
        });
        formData.append("newDocsList", JSON.stringify(newDocsList));

        const method = request.id ? "put" : "post";

        axios[method](
            `${siteHost}/api/request`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default {
    state: {
        requests: [],
        filterRequests: cleanFilterState,

        filterRequestsRepair: {},
        filterRequestsRepairTool: {},
        filterRequestsSoft: {},
        reqsCount: 0,

        request: {},
        filesList: [],
        requestEngineers: [],
        requestHistory: [],

        requestIsLoading: false,
    },
    // =========================================================================
    getters: {
        requests: (state) => state.requests,
        getReqKindTitle: () => (reqKindId) => {
            const reqKind = REQUEST_KINDS.find((rk) => rk.id === reqKindId);
            return reqKind
                ? reqKind.title
                : "Неизвестный тип";
        },
        getReqStatusTitle: () => (reqStatusId) => {
            const reqStatus = REQUEST_STATUSES.find((rs) => rs.id === reqStatusId);
            return reqStatus
                ? reqStatus.title
                : `!!! Неизвестный статус (${reqStatusId}) !!!`;
        },
        filterRequests: (state) => state.filterRequests,
        filterRequestsRepair: (state) => state.filterRequestsRepair,
        filterRequestsRepairTool: (state) => state.filterRequestsRepairTool,
        filterRequestsSoft: (state) => state.filterRequestsSoft,
        reqsCount: (state) => state.reqsCount,

        request: (state) => state.request,
        filesList: (state) => state.filesList,
        requestEngineers: (state) => state.requestEngineers,
        requestHistory: (state) => state.requestHistory,

        requestIsLoading: (state) => state.requestIsLoading,
    },
    // =========================================================================
    actions: {
        //----------------------------------------------------------------------
        // get requests from DB
        getRequestsFromDB({ commit, state, getters }) {
            return new Promise((resolve, reject) => {
                // if can't read - return
                if (!(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.OWN) &&
                    !(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)) {
                    return;
                }

                const filterL = {
                    ...state.filterRequests,
                    pageNumber: getters.reqsPageNumber,
                };

                commit("setMainSpinnerState", true);
                axios.get(
                    `${siteHost}/api/request`,
                    {
                        params: {
                            filter: filterL,
                            filterRequestsRepair: getters.filterRequestsRepair,
                            filterRequestsRepairTool: getters.filterRequestsRepairTool,
                            filterRequestsSoft: getters.filterRequestsSoft,
                        },
                    },
                )
                    .then((res) => {
                        commit("setRequests", res.data.requests);
                        commit("setReqsPagesCount", res.data.pagesCount);
                        commit("setReqsCount", res.data.itemsCount);

                        if (getters.reqsPageNumber > res.data.pagesCount) {
                            commit("setReqsPageNumber", 1);
                        }

                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        commit("setMainSpinnerState", false);
                    });
            });
        },
        async getAllRequestsFromDB({ state, getters }, docType) {
            const filterL = {
                ...state.filterRequests,
                allRequests: true,
            };

            const res = await axios.get(
                `${siteHost}/api/request`,
                {
                    params: {
                        filter: filterL,
                        filterRequestsRepair: getters.filterRequestsRepair,
                        filterRequestsRepairTool: getters.filterRequestsRepairTool,
                        filterRequestsSoft: getters.filterRequestsSoft,
                    },
                },
            );

            if (docType === "docx") {
                saveRequestsToDocx(
                    res.data.requests,
                    getters,
                );
            }
            else {
                saveRequestsToXlsx(
                    res.data.requests,
                    getters,
                );
            }

            return res.data.requests;
        },
        setFilterRequests({ commit, dispatch }, filter) {
            return new Promise((resolve) => {
                commit("setFilterRequests", filter);
                commit("setReqsPageNumber", 1);
                dispatch("getRequestsFromDB");

                resolve();
            });
        },
        setFilterRequestsRepairTool({ commit, dispatch }, filter) {
            return new Promise((resolve) => {
                commit("setFilterRequestsRepairTool", filter);
                commit("setReqsPageNumber", 1);
                dispatch("getRequestsFromDB");

                resolve();
            });
        },
        cleanReqsFilter({ commit, dispatch }) {
            return new Promise((resolve) => {
                commit("setFilterRequestsRepair", {});
                commit("setFilterRequestsRepairTool", {});
                commit("setFilterRequestsSoft", {});
                dispatch(
                    "setFilterRequests",
                    cleanFilterState,
                )
                    .then(() => {
                        resolve();
                    });
            });
        },
        setFilterRequestsRepair({ commit, dispatch }, payload) {
            commit("setFilterRequestsRepair", payload);
            commit("setReqsPageNumber", 1);
            dispatch("getRequestsFromDB");
        },
        setFilterRequestsSoft({ commit, dispatch }, payload) {
            commit("setFilterRequestsSoft", payload);
            commit("setReqsPageNumber", 1);
            dispatch("getRequestsFromDB");
        },
        getFilesListFromDB(context, { section, id }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_files/${section}/${id}`)
                    .then((res) => {
                        resolve(res.data.files);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRequestFile({ commit }, file) {
            commit("addRequestFile", file);
        },
        delRequestFile({ commit }, index) {
            commit("delRequestFile", index);
        },
        getRequestEngineers({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_engineers/${reqId}`)
                    .then((res) => {
                        commit("setRequestEngineers", res.data.engineers);
                        resolve(res.data.engineers);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRequestEngineers({ commit }, engineersIds) {
            return new Promise((resolve) => {
                commit("setRequestEngineers", engineersIds);
                resolve();
            });
        },
        getRequestHistory({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_history/${reqId}`)
                    .then((res) => {
                        commit("setRequestHistory", res.data.request_history);
                        resolve(res.data.request_history);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //----------------------------------------------------------------------
        // working instance
        setRequestField({ commit }, payload) {
            return new Promise((resolve) => {
                commit("setRequestField", payload);
                resolve();
            });
        },
        setRequest({ commit, dispatch }, request) {
            return new Promise((resolve) => {
                commit(
                    "setRequest",
                    {
                        request_date_add: new Date(),
                        request_status_id: REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                        request_performer_kind: PERFORM_KIND.FILIAL,
                        contact_kind: CONTACT_KIND.FROM_IAC,
                        ...request,
                    },
                );

                if (request.id) {
                    commit("setRequestLoading", true);

                    const p1 = dispatch(
                        "getFilesListFromDB",
                        {
                            section: "request",
                            id: request.id,
                        },
                    );
                    const p2 = dispatch("getRequestDocs", request.id);
                    const p3 = dispatch("getRequestWorks", request.id);
                    const p4 = dispatch("getRequestSpares", request.id);
                    const p5 = dispatch("getRequestEngineers", request.id);
                    const p6 = dispatch("getRequestHistory", request.id);
                    const p7 = dispatch("getRequestComments", request.id);

                    Promise
                        .all([p1, p2, p3, p4, p5, p6, p7])
                        .then((values) => {
                            commit("setRequestFilesList", values[0]);
                        })
                        .finally(() => {
                            resolve();
                            commit("setRequestLoading", false);
                        });
                }
                else {
                    // new request
                    commit("setRequestFilesList", []);
                    commit("setRequestDocs", []);
                    commit("setRequestWorks", []);
                    commit("setRequestSpares", []);
                    commit("setRequestEngineers", []);
                    commit("setRequestHistory", []);
                    commit("setRequestComments", []);
                    resolve();
                }
            });
        },
        //----------------------------------------------------------------------
        // save
        saveRequest(
            {
                state,
                commit,
                dispatch,
                getters,
            },
            resetCurrentRequest,
        ) {
            if (!state.request.id && state.request.request_performer_kind === PERFORM_KIND.FILIAL) {
                const engineersOfUnit = getters.engineersOfUnit(state.request.unit_id);
                if (engineersOfUnit.length) {
                    commit("setRequestEngineers", [engineersOfUnit[0].user_id]);
                }
            }

            return new Promise((resolve, reject) => {
                saveRequestToDB(
                    state.request,
                    getters.requestEngineers,
                    getters.reqWorks,
                    getters.reqSpares,
                    getters.filesList,
                    getters.requestDocs,
                )
                    .then(async (responseData) => {
                        await dispatch("getRequestsFromDB");
                        await dispatch("getSparesSetsItemsFromDB");

                        if (!state.request.id) {
                            await dispatch("setRequest", {});
                        }
                        else if (resetCurrentRequest) {
                            await dispatch("setRequest", responseData.savedRequest);
                        }

                        resolve(responseData.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async saveMultipleRequests(
            { commit, dispatch, getters },
            {
                requestText,
                unitsIds,
                filesList,
            },
        ) {
            commit("setMainSpinnerState", true);

            for (let i = 0; i < unitsIds.length; i++) {
                const engineersOfUnit = getters.engineersOfUnit(unitsIds[i]);
                const requestEngineers = engineersOfUnit.length
                    ? [engineersOfUnit[0].user_id]
                    : [];

                await saveRequestToDB(
                    {
                        request_status_id: REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                        request_performer_kind: PERFORM_KIND.FILIAL,
                        contact_kind: CONTACT_KIND.STRANGER,
                        contact_unknown_name: [
                            getters.currentUser.surname,
                            getters.currentUser.name,
                            getters.currentUser.patronymic,
                        ].join(" "),
                        contact_unknown_email: getters.currentUser.email,
                        request_text: requestText,
                        unit_id: unitsIds[i],
                        contact_id: null,
                        tool_id: null,
                        doNotSendEmail: true,
                    },
                    requestEngineers,
                    [], // getters.reqWorks,
                    [], // getters.reqSpares,
                    filesList,
                    [], // getters.requestDocs,
                );
            }

            dispatch("getRequestsFromDB");

            commit("setMainSpinnerState", false);
        },
        deleteRequest({ dispatch }, requestId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/request/${requestId}`)
                    .then((response) => {
                        dispatch("getRequestsFromDB");
                        resolve(response.data.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRequestNewNumber({ dispatch }, { request, newNumber }) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_set_new_number`,
                    {
                        requestId: request.id,
                        newNumber,
                    },
                )
                    .then((response) => {
                        dispatch("getRequestsFromDB");
                        resolve(response.data.message);
                    })
                    .catch((err) => {
                        Notify.create({
                            type: "notify-failure",
                            message: err.response.data.message,
                        });

                        reject(err);
                    });
            });
        },
        //----------------------------------------------------------------------
        setRequestLoading({ commit }, v) {
            return new Promise((resolve) => {
                commit("setRequestLoading", v);
                resolve();
            });
        },
    },
    // =========================================================================
    mutations: {
        setRequests(state, requests) {
            state.requests = requests;
        },
        setFilterRequests(state, filter) {
            state.filterRequests = filter;
        },
        setFilterRequestsRepair(state, filterRequestsRepair) {
            state.filterRequestsRepair = filterRequestsRepair;
        },
        setFilterRequestsRepairTool(state, filterRequestsRepairTool) {
            state.filterRequestsRepairTool = filterRequestsRepairTool;
        },
        setFilterRequestsSoft(state, filterRequestsSoft) {
            state.filterRequestsSoft = filterRequestsSoft;
        },
        //----------------------------------------------------------------------
        setRequestField(state, { field, value }) {
            state.request = {
                ...state.request,
                [field]: value,
            };
        },
        setRequest(state, request) {
            state.request = request;
        },
        setReqsCount(state, reqsCount) {
            state.reqsCount = reqsCount;
        },
        setRequestFilesList(state, filesList) {
            state.filesList = filesList;
        },
        addRequestFile(state, file) {
            state.filesList.push(file);
        },
        delRequestFile(state, index) {
            state.filesList.splice(index, 1);
        },
        setRequestEngineers(state, requestEngineers) {
            state.requestEngineers = requestEngineers;
        },
        setRequestHistory(state, requestHistory) {
            state.requestHistory = requestHistory;
        },
        setRequestLoading(state, v) {
            state.requestIsLoading = v;
        },
    },
};
