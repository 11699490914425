<template>
    <q-dialog
        ref="dialog"
        persistent
        fullWidth
        fullHeight
    >
        <q-card class="dialog-vh-card">
            <div class="text-h6 dialog-title">
                История изменений
            </div>

            <q-separator />

            <q-editor
                v-model="versionsHistoryL"
                class="dialog-vh-editor"
                contentClass="dialog-vh-editor-content"
                :disable="!canBeEdited"
                :toolbar="[
                    ['left', 'center', 'right', 'justify'],
                    ['bold', 'italic', 'underline'],
                    [
                        {
                            label: 'Форматирование',
                            icon: $q.iconSet.editor.formatting,
                            list: 'no-icons',
                            options: [
                                'p',
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'code'
                            ]
                        },
                        {
                            label: 'Размер шрифта',
                            icon: $q.iconSet.editor.fontSize,
                            fixedLabel: true,
                            fixedIcon: true,
                            list: 'no-icons',
                            options: [
                                'size-1',
                                'size-2',
                                'size-3',
                                'size-4',
                                'size-5',
                                'size-6',
                                'size-7'
                            ]
                        },
                        {
                            label: 'Шрифт',
                            icon: $q.iconSet.editor.font,
                            fixedIcon: true,
                            list: 'no-icons',
                            options: [
                                'default_font',
                                'arial',
                                'arial_black',
                                'comic_sans',
                                'courier_new',
                                'impact',
                                'lucida_grande',
                                'times_new_roman',
                                'verdana'
                            ]
                        },
                        'removeFormat'
                    ],
                    ['unordered', 'ordered', 'outdent', 'indent'],
                ]"
                :fonts="{
                    arial: 'Arial',
                    arial_black: 'Arial Black',
                    comic_sans: 'Comic Sans MS',
                    courier_new: 'Courier New',
                    impact: 'Impact',
                    lucida_grande: 'Lucida Grande',
                    times_new_roman: 'Times New Roman',
                    verdana: 'Verdana'
                }"
            />
            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="canBeEdited"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    :label="canBeEdited ? 'Отменить' : 'Закрыть'"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";

export default {
    name: "DialogEditVersionsHistory",
    data() {
        return {
            versionsHistoryL: "",
        };
    },
    computed: {
        ...mapGetters({
            versionsHistory: "versionsHistory",
            currentUser: "currentUser",
        }),
        canBeEdited() {
            console.log(this.currentUser.rightsObject.RIGHT_APP_VERSIONS_EDIT);
            return !!(this.currentUser.rightsObject.RIGHT_APP_VERSIONS_EDIT & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        show() {
            this.versionsHistoryL = this.versionsHistory;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$store.dispatch("saveVersionsHistory", this.versionsHistoryL);
            this.$refs.dialog.hide();
        },
    },
};
</script>
