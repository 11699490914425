import XlsxPopulate from "xlsx-populate";
import date from 'quasar/src/utils/date/date.js';;
import { siteHost } from "../store/constants";
import {
    cellVS2,
    saveFile,
    guarantyRemains,
    toolsStateName,
} from "./helpers";

export function saveModelsToXlsx(
    manufacturers,
    models,
) {
    const url = `${siteHost}/public/templates/manufacturers-models-list.xlsx`;

    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onreadystatechange = function r() {
        if (req.readyState === 4 && req.status === 200) {
            XlsxPopulate.fromDataAsync(req.response)
                .then(async (workbook) => {
                    for (let iM = 0; iM < models.length; iM++) {
                        const m = models[iM];

                        const cA = workbook.sheet(0).cell(`A${iM + 5}`);
                        cellVS2({ cell: cA, value: `${iM + 1}` });
                        const cB = workbook.sheet(0).cell(`B${iM + 5}`);
                        cellVS2({ cell: cB, value: m.toolKindName });
                        const cC = workbook.sheet(0).cell(`C${iM + 5}`);
                        cellVS2({ cell: cC, value: m.manufacturerName });
                        const cD = workbook.sheet(0).cell(`D${iM + 5}`);
                        cellVS2({ cell: cD, value: m.model });
                    }

                    for (let iM = 0; iM < manufacturers.length; iM++) {
                        const m = manufacturers[iM];

                        const cA = workbook.sheet(1).cell(`A${iM + 5}`);
                        cellVS2({ cell: cA, value: `${iM + 1}` });
                        const cB = workbook.sheet(1).cell(`B${iM + 5}`);
                        cellVS2({ cell: cB, value: m.title });
                    }

                    // Get the output
                    return workbook.outputAsync("blob");
                })
                .then((blob) => {
                    const fileName = "Модели_производители.xlsx";
                    saveFile(fileName, blob);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    req.send();
}

export async function saveToolsToXlsx(
    tools,
    {
        unitsFull,
        toolsSetName,
        getToolKindName,
        getManufacturerName,
        getToolModelName,
    },
) {
    const headers = [
        { col: "A", label: "№" },
        { col: "B", label: "Объект, помещение" },
        { col: "C", label: "Вид комплекта" },
        { col: "D", label: "Вид оборудования" },
        { col: "E", label: "Производитель" },
        { col: "F", label: "Наименование (Модель)" },
        { col: "G", label: "Серийный номер" },
        { col: "H", label: "Инвентарный номер" },
        { col: "I", label: "Дата ввода в экспл." },
        { col: "J", label: "Состояние" },
        { col: "K", label: "Остаток гарантийного срока" },
        { col: "L", label: "Примечание" },
    ];

    const url = `${siteHost}/public/templates/equipment-lst.xlsx`;

    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onreadystatechange = function r() {
        if (req.readyState === 4 && req.status === 200) {
            XlsxPopulate.fromDataAsync(req.response)
                .then(async (workbook) => {
                    // Date
                    const cDate = workbook.sheet(0).cell("A2");
                    const dateToolsList = date.formatDate(new Date(), "DD.MM.YYYY");
                    cellVS2({ cell: cDate, value: dateToolsList });
                    // Headers
                    for (let i = 0; i < headers.length; i++) {
                        const header = headers[i];
                        const cHeader = workbook.sheet(0).cell(`${header.col}3`);
                        cellVS2({ cell: cHeader, value: header.label });
                    }
                    // Data
                    for (let i = 0; i < tools.length; i++) {
                        const tool = tools[i];

                        const unit = unitsFull.find((u) => u.id === tool.unit_id);
                        let unitName = unit ? unit.name : "";
                        if (tool.tool_places.length > 0) {
                            unitName = `${unitName}, пом. ${tool.tool_places[0].room}`;
                        }

                        const guarantyS = guarantyRemains(tool.guaranty_dt);

                        const cA = workbook.sheet(0).cell(`A${i + 4}`);
                        cellVS2({ cell: cA, value: `${i + 1}` });
                        const cB = workbook.sheet(0).cell(`B${i + 4}`);
                        cellVS2({ cell: cB, value: unitName });
                        const cC = workbook.sheet(0).cell(`C${i + 4}`);
                        cellVS2({ cell: cC, value: toolsSetName(tool.set_id) || "" });
                        const cD = workbook.sheet(0).cell(`D${i + 4}`);
                        cellVS2({ cell: cD, value: getToolKindName(tool.tool_kind_id) || "" });
                        const cE = workbook.sheet(0).cell(`E${i + 4}`);
                        cellVS2({ cell: cE, value: getManufacturerName(tool.manufacturer_id) || "" });
                        const cF = workbook.sheet(0).cell(`F${i + 4}`);
                        cellVS2({ cell: cF, value: getToolModelName(tool.model_id) || "" });
                        const cG = workbook.sheet(0).cell(`G${i + 4}`);
                        cellVS2({ cell: cG, value: tool.serial_number });
                        const cH = workbook.sheet(0).cell(`H${i + 4}`);
                        cellVS2({ cell: cH, value: tool.inventar_number });
                        const cI = workbook.sheet(0).cell(`I${i + 4}`);
                        cellVS2({ cell: cI, value: date.formatDate(tool.work_in_dt, "DD.MM.YYYY") });
                        const cJ = workbook.sheet(0).cell(`J${i + 4}`);
                        cellVS2({ cell: cJ, value: toolsStateName(tool.state) || "" });
                        const cK = workbook.sheet(0).cell(`K${i + 4}`);
                        cellVS2({ cell: cK, value: guarantyS });
                        const cL = workbook.sheet(0).cell(`L${i + 4}`);
                        cellVS2({ cell: cL, value: tool.description || "" });
                    }

                    // Get the output
                    return workbook.outputAsync("blob");
                })
                .then((blob) => {
                    const fileName = "equipment_list.xlsx";
                    saveFile(fileName, blob);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    req.send();
}

export async function saveRequestsToXlsx(
    requests,
    {
        getReqKindTitle,
        getReqStatusTitle,
        getUserPostTitle,
        unitsFull,
        getUserFIO,
    },
) {
    const headers = [
        { col: "A", label: "№ п/п" },
        { col: "B", label: "№ заявки" },
        { col: "C", label: "Дата регистрации" },
        { col: "D", label: "№ заявки на ГЛ" },
        { col: "E", label: "Организация, ФИО заявителя, должность" },
        { col: "F", label: "Содержание" },
        { col: "G", label: "Тип заявки" },
        { col: "H", label: "Состояние" },
        { col: "I", label: "Дата исполнения" },
    ];

    const url = `${siteHost}/public/templates/requests-list.xlsx`;

    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onreadystatechange = function r() {
        if (req.readyState === 4 && req.status === 200) {
            XlsxPopulate.fromDataAsync(req.response)
                .then(async (workbook) => {
                    // Headers
                    for (let i = 0; i < headers.length; i++) {
                        const header = headers[i];
                        const cHeader = workbook.sheet(0).cell(`${header.col}2`);
                        cellVS2({ cell: cHeader, value: header.label });
                    }
                    // Data
                    for (let i = 0; i < requests.length; i++) {
                        const request = requests[i];

                        const emplPostTitle = getUserPostTitle(request.contact_id);
                        const unit = unitsFull.find((u) => u.id === request.unit_id);
                        const unitName = [
                            unit ? unit.name : "",
                            unit ? unit.address : "",
                            getUserFIO(request.contact_id),
                            emplPostTitle ? `${emplPostTitle}` : "",
                        ]
                            .join("\n");

                        const cA = workbook.sheet(0).cell(`A${i + 3}`);
                        cellVS2({ cell: cA, value: `${i + 1}` });
                        const cB = workbook.sheet(0).cell(`B${i + 3}`);
                        cellVS2({ cell: cB, value: request.number });
                        const cC = workbook.sheet(0).cell(`C${i + 3}`);
                        cellVS2({ cell: cC, value: date.formatDate(request.request_date_add, "DD.MM.YYYY") });
                        const cD = workbook.sheet(0).cell(`D${i + 3}`);
                        cellVS2({ cell: cD, value: request.hotlinegas_number || "" });
                        const cE = workbook.sheet(0).cell(`E${i + 3}`);
                        cellVS2({ cell: cE, value: unitName });
                        const cF = workbook.sheet(0).cell(`F${i + 3}`);
                        cellVS2({ cell: cF, value: request.request_text.replace(/"/g, "\u{201D}") });
                        const cG = workbook.sheet(0).cell(`G${i + 3}`);
                        cellVS2({ cell: cG, value: getReqKindTitle(request.request_kind_id) });
                        const cH = workbook.sheet(0).cell(`H${i + 3}`);
                        cellVS2({ cell: cH, value: getReqStatusTitle(request.request_status_id) });
                        const cI = workbook.sheet(0).cell(`I${i + 3}`);
                        cellVS2({ cell: cI, value: request.result_d ? date.formatDate(request.result_d, "DD.MM.YYYY") : "" });
                    }

                    // Get the output
                    return workbook.outputAsync("blob");
                })
                .then((blob) => {
                    const fileName = "requests_list.xlsx";
                    saveFile(fileName, blob);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    req.send();
}
