import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        reqSpares: [],
    },
    getters: {
        reqSpares: (state) => state.reqSpares,
        reqSparesCost: (state) => state.reqSpares.reduce(
            (acc, spare) => (
                acc + (+spare.cost * +spare.amount)
            ),
            0,
        ),
    },
    actions: {
        getRequestSpares({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_spares/${reqId}`)
                    .then((res) => {
                        commit("setRequestSpares", res.data.request_spares);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRequestSpare({ commit }, spare) {
            commit("addRequestSpare", spare);
        },
        delRequestSpare({ commit }, index) {
            commit("delRequestSpare", index);
        },
        setRequestSpare({ commit }, payload) {
            commit("setRequestSpare", payload);
        },
        clearSpares({ commit }) {
            commit("clearSpares");
        },
    },
    mutations: {
        setRequestSpares(state, spares) {
            state.reqSpares = spares;
        },
        addRequestSpare(state, spare) {
            state.reqSpares.push(spare);
            /*
            state.reqSpares.push({
                spare_id: null,
                amount: 0,
            });
            */
        },
        setRequestSpare(state, { index, spare }) {
            state.reqSpares = state.reqSpares
                .map((oldSpare, ind) => (
                    ind === index
                        ? spare
                        : oldSpare
                ));
        },
        delRequestSpare(state, index) {
            state.reqSpares.splice(index, 1);
        },
        clearSpares(state) {
            state.reqSpares = [];
        },
    },
};
