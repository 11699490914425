<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 70vw; max-width: 70vw;">
            <q-card-section>
                <div class="text-h6">
                    Комплект ЗИП
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    v-model="sparesSet.region_id"
                    :options="regions"
                    label="Регион"
                    class="q-mb-sm"
                />
                <q-input
                    ref="refTitle"
                    v-model="sparesSet.title"
                    outlined
                    dense
                    :rules="[val => val && 0 < val.length && val.length < 100 ||
                        'Значение от 1 до 100 символов']"
                    hideBottomSpace
                    label="Наименование ЗИП"
                    class="q-mb-sm"
                />
                <q-input
                    ref="refContract"
                    v-model="sparesSet.contract"
                    outlined
                    dense
                    :rules="[val => val && 0 < val.length && val.length < 100 ||
                        'Значение от 1 до 100 символов']"
                    hideBottomSpace
                    label="Договор"
                    class="q-mb-sm"
                />
                <div class="spare-set-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Наименование
                    </div>
                    <div class="cell cell-header cell-center">
                        Стоимость
                    </div>
                    <div class="cell cell-header cell-center">
                        Количество
                    </div>
                    <div class="cell cell-header cell-center">
                        <q-btn
                            round
                            :icon="matAdd"
                            size="sm"
                            color="primary"
                            @click="addSpare"
                        >
                            <q-tooltip
                                :delay="300"
                                anchor="top right"
                                self="bottom middle"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Добавить параметр
                            </q-tooltip>
                        </q-btn>
                    </div>
                    <div
                        v-for="(item, index) in sparesSet.items"
                        :key="index"
                        class="row-wrapper"
                    >
                        <div class="cell cell-center">
                            {{ index + 1 }}
                        </div>
                        <div class="cell cell-center">
                            <q-input
                                :ref="`refTitle_${index}`"
                                v-model="sparesSet.items[index].title"
                                borderless
                                dense
                                :rules="[val => val &&
                                    0 < val.trim().length &&
                                    val.trim().length < 100 || 'Наименование от 1 до 100 символов']"
                                hideBottomSpace
                            />
                        </div>
                        <div class="cell cell-center">
                            <q-input
                                :ref="`refCost_${index}`"
                                v-model="sparesSet.items[index].cost"
                                borderless
                                dense
                                type="number"
                                :rules="[val => !!val || 'Cтоимость ?']"
                                hideBottomSpace
                            />
                        </div>
                        <div class="cell cell-center">
                            <q-input
                                :ref="`refAmount_${index}`"
                                v-model="sparesSet.items[index].amount"
                                borderless
                                dense
                                type="number"
                                :rules="[val => !!val || 'Kоличество ?']"
                                hideBottomSpace
                            />
                        </div>
                        <div class="cell cell-center">
                            <q-btn
                                v-if="sparesSet.items.length > 1"
                                round
                                :icon="matDelete"
                                size="sm"
                                class="poeso-table--control-button"
                                @click="() => removeSpare(index)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-if="allowZipWrite"
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "DialogEditSparesSet",
    components: {
        SelectFilter,
    },
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                title: "",
                contract: "",
                items: [],
            }),
        },
    },
    emits: ["ok"],
    data() {
        return {
            sparesSet: {
                title: "",
                contract: "",
                items: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            currentUser: "currentUser",
        }),
        allowZipWrite() {
            return !!(this.currentUser.rightsObject.RIGHT_ZIP & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        addSpare() {
            this.sparesSet.items.push({
                title: "",
                cost: "",
                amount: "",
            });
        },
        removeSpare(index) {
            this.sparesSet.items.splice(index, 1);
        },
        show() {
            this.sparesSet = {
                title: "",
                contract: "",
                items: [],
                ...this.itemForEdit,
            };
            if (this.sparesSet.items.length === 0) {
                this.addSpare();
            }
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            const bError = Object.keys(this.$refs).reduce((acc, key) => {
                // TODO: something strange with refs in quasar
                // console.log(key);
                if (Array.isArray(this.$refs[key]) &&
                    this.$refs[key][0] &&
                    this.$refs[key][0].validate) {
                    // if (key.startsWith("ref")) {
                    this.$refs[key][0].validate();
                    return acc || this.$refs[key][0].hasError;
                }
                if (this.$refs[key].validate) {
                    this.$refs[key].validate();
                    return acc || this.$refs[key].hasError;
                }

                return acc;
            }, false);

            if (bError) {
                return;
            }

            this.$emit("ok", this.sparesSet);

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
