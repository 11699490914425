<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Смена номера заявки
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    v-model="oldNumber"
                    outlined
                    dense
                    label="Текущий номер заявки"
                    class="q-mb-sm"
                    readonly
                />
                <q-input
                    ref="refNewNumber"
                    v-model="newNumber"
                    outlined
                    dense
                    label="Новый номер заявки"
                    hideBottomSpace
                    :rules="[(val) => val !== null && /^\d+$/.test(val) || 'Введите целое число']"
                />
            </q-card-section>

            <q-btn
                flat
                label="Сменить номер"
                color="primary"
                @click="changeNumber"
            />

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogChangeRequestNumber",
    props: {
        request: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            oldNumber: 1,
            newNumber: null,
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.oldNumber = this.request.number;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        changeNumber() {
            this.$refs.refNewNumber.validate();

            if (this.$refs.refNewNumber.hasError) {
                return;
            }

            this.$store.dispatch(
                "setRequestNewNumber",
                {
                    request: this.request,
                    newNumber: this.newNumber,
                },
            )
                .then(() => {
                    this.hide();
                });
        },
    },
};
</script>
