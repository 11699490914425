import date from 'quasar/src/utils/date/date.js';;
import { createDoc, saveDoc } from "./createDoc";
import { tCellP } from "./tableCell";
import { guarantyRemains, toolsStateName } from "./helpers";

//------------------------------------------------------------------------------
// tools
function getTableToolsList(
    tools,
    {
        unitsFull,
        toolsSetName,
        getToolKindName,
        getManufacturerName,
        getToolModelName,
    },
) {
    const tableWidth = 14400;
    const header = [
        `
        <w:tblPr>
            <w:jc w:val="center"/>
            <w:tblW w:type="dxa" w:w="${tableWidth}"/>
            <w:tblBorders>
                <w:top w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:start w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:bottom w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:end w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:insideH w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:insideV w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
            </w:tblBorders>
            <w:tblCellMar>
                <w:top w:w="100" w:type="dxa"/>
                <w:start w:w="100" w:type="dxa"/>
                <w:bottom w:w="20" w:type="dxa"/>
                <w:end w:w="100" w:type="dxa"/>
            </w:tblCellMar>
        </w:tblPr>
        <w:tblGrid>
            <w:gridCol w:w="${Math.round(tableWidth * 0.03)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.11)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.08)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.12)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.10)}" />
        </w:tblGrid>
        `,
        "<w:tr>",
        tCellP("№", { fontSize: "20", pAlign: "center" }),
        tCellP("Объект, помещение", { fontSize: "20", pAlign: "center" }),
        tCellP("Вид комплекта", { fontSize: "20", pAlign: "center" }),
        tCellP("Вид оборудования", { fontSize: "20", pAlign: "center" }),
        tCellP("Производитель", { fontSize: "20", pAlign: "center" }),
        tCellP("Наименование (Модель)", { fontSize: "20", pAlign: "center" }),
        tCellP("Серийный номер", { fontSize: "20", pAlign: "center" }),
        tCellP("Инвентарный номер", { fontSize: "20", pAlign: "center" }),
        tCellP("Дата ввода в экспл.", { fontSize: "20", pAlign: "center" }),
        tCellP("Состояние", { fontSize: "20", pAlign: "center" }),
        tCellP("Остаток гарантийного срока", { fontSize: "20", pAlign: "center" }),
        tCellP("Примечание", { fontSize: "20", pAlign: "center" }),
        "</w:tr>",
    ].join("");

    let data = "";

    for (let i = 0; i < tools.length; i++) {
        const tool = tools[i];

        const unit = unitsFull.find((u) => u.id === tool.unit_id);

        const guarantyS = guarantyRemains(tool.guaranty_dt);

        let unitName = unit ? unit.name : "";
        if (tool.tool_places.length > 0) {
            // ???
            // if (tool.tool_places[0].unit_id !== unit.id) {
            //     const unitP = unitsFull.find((u) => u.id === tool.tool_places[0].unit_id);
            // }

            unitName = `${unitName}, пом. ${tool.tool_places[0].room}`;
        }

        const line = [
            "<w:tr>",
            tCellP(`${i + 1}`, { fontSize: "16", pAlign: "center" }),
            tCellP(unitName, { fontSize: "16", pAlign: "center" }),
            tCellP(toolsSetName(tool.set_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getToolKindName(tool.tool_kind_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getManufacturerName(tool.manufacturer_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getToolModelName(tool.model_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(tool.serial_number, { fontSize: "16", pAlign: "center" }),
            tCellP(tool.inventar_number, { fontSize: "16", pAlign: "center" }),
            tCellP(date.formatDate(tool.work_in_dt, "DD.MM.YYYY"), { fontSize: "16", pAlign: "center" }),
            tCellP(toolsStateName(tool.state) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(guarantyS, { fontSize: "16", pAlign: "center" }),
            tCellP(tool.description || "", { fontSize: "16", pAlign: "center" }),
            "</w:tr>",
        ].join("");

        data = `${data}${line}`;
    }

    return `<w:tbl>${header}${data}</w:tbl>`;
}

export async function saveToolsToDocx(
    tools,
    storeGetters,
) {
    const replacement = {
        dateToolsList: date.formatDate(new Date(), "DD.MM.YYYY"),
        tableToolsList: getTableToolsList(tools, storeGetters),
    };

    const toolsList = await createDoc(
        "equipment-lst.docx",
        "equipment-lst-2.docx",
        replacement,
    );
    saveDoc(toolsList);
}
//------------------------------------------------------------------------------
// requests
async function getRequestsTable(
    requestsAll,
    {
        getReqKindTitle,
        getReqStatusTitle,
        getUserPostTitle,
        unitsFull,
        getUserFIO,
    },
) {
    const tableWidth = 15285;

    const headerAttrs = {
        vAlign: "center",
        pAlign: "center",
        pPrBold: true,
        fontSize: 20,
    };

    const dataAttrs = {
        pAlign: "center",
        fontSize: 20,
    };

    const header = `
    <w:tblPr>
    <w:jc w:val="center"/>
    <w:tblW w:type="dxa" w:w="${tableWidth}"/>
    <w:tblBorders>
        <w:top w:val="single" w:sz="1" w:space="1" w:color="000000" />
        <w:start w:val="single" w:sz="1" w:space="1" w:color="000000" />
        <w:bottom w:val="single" w:sz="1" w:space="1" w:color="000000" />
        <w:end w:val="single" w:sz="1" w:space="1" w:color="000000" />
        <w:insideH w:val="single" w:sz="1" w:space="1" w:color="000000" />
        <w:insideV w:val="single" w:sz="1" w:space="1" w:color="000000" />
    </w:tblBorders>
    <w:tblCellMar>
        <w:top w:w="100" w:type="dxa"/>
        <w:start w:w="100" w:type="dxa"/>
        <w:bottom w:w="100" w:type="dxa"/>
        <w:end w:w="100" w:type="dxa"/>
    </w:tblCellMar>
    </w:tblPr>
    <w:tblGrid>
        <w:gridCol w:w="${Math.round(tableWidth * 0.035)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.165)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.195)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.130)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.110)}" />
        <w:gridCol w:w="${Math.round(tableWidth * 0.095)}" />
    </w:tblGrid>
    <w:tr>
    ${tCellP("№ п/п", headerAttrs)}
    ${tCellP("№ заявки", headerAttrs)}
    ${tCellP("Дата регистрации", headerAttrs)}
    ${tCellP("№ заявки на ГЛ", headerAttrs)}
    ${tCellP("Организация, ФИО заявителя, должность", headerAttrs)}
    ${tCellP("Содержание", headerAttrs)}
    ${tCellP("Тип заявки", headerAttrs)}
    ${tCellP("Состояние", headerAttrs)}
    ${tCellP("Дата исполнения", headerAttrs)}
    </w:tr>
    <w:tr>
    ${tCellP("1", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("2", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("3", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("4", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("5", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("6", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("7", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("8", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    ${tCellP("9", { pAlign: "center", pPrBold: true, fontSize: 20 })}
    </w:tr>
    `;
    let data = "";

    for (let i = 0; i < requestsAll.length; i++) {
        const request = requestsAll[i];

        const emplPostTitle = getUserPostTitle(request.contact_id);
        const unit = unitsFull.find((u) => u.id === request.unit_id);
        const unitName = [
            unit ? unit.name : "",
            unit ? unit.address : "",
            getUserFIO(request.contact_id),
            emplPostTitle ? `${emplPostTitle}` : "",
        ]
            .join("\n");

        const row = [
            tCellP(i + 1, dataAttrs),
            tCellP(request.number, dataAttrs),
            tCellP(date.formatDate(request.request_date_add, "DD.MM.YYYY"), dataAttrs),
            tCellP(request.hotlinegas_number || "", dataAttrs),
            tCellP(unitName, dataAttrs),
            tCellP(
                request.request_text.replace(/"/g, "\u{201D}"),
                dataAttrs,
            ),
            tCellP(
                getReqKindTitle(request.request_kind_id),
                dataAttrs,
            ),
            tCellP(
                getReqStatusTitle(request.request_status_id),
                dataAttrs,
            ),
            tCellP(
                request.result_d ? date.formatDate(request.result_d, "DD.MM.YYYY") : "",
                dataAttrs,
            ),
        ]
            .join("");
        // .replace(/"/g, "A");
        // .replace(/&quot;/g, "\"");

        data = `${data}<w:tr>${row}</w:tr>`;
    }

    return `<w:tbl>${header}${data}</w:tbl>`;
}

export async function saveRequestsToDocx(
    requests,
    getters,
) {
    console.log(requests.length);

    const replacement = {
        tableRequestsList: await getRequestsTable(requests, getters),
    };

    const requestsList = await createDoc(
        "requests-list.docx",
        "requests-list.docx",
        replacement,
    );
    saveDoc(requestsList);
}
